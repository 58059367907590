@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.wiki-how-card {
  display: inline-block;
  min-width: 211px;

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  &__img {
    display: block;
    width: auto;
    max-width: 100%;
    min-height: 159px;
    object-fit: cover;
    border-radius: $border-radius-m $border-radius-m 0 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $spacing * 4;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    margin: 0 0 auto;

    @include themify($themes) {
      color: themed('textPrimary');
    }

    @include truncate(3);

    @include minSm {
      font-size: 20px;
    }
  }

  &__info {
    display: flex;
    align-items: flex-end;
    margin: 0;

    &:first-of-type {
      margin-top: $spacing * 3;
      margin-bottom: 6px;
    }
  }

  &__info-text {
    font-size: 14px;
    line-height: 16px;

    @include themify($themes) {
      color: themed('textTertiary');
    }
  }

  &__info-icon {
    margin-right: $spacing * 2;

    @include themify($themes) {
      color: themed('iconPrimary');
    }
  }

  &__logo {
    margin-top: $spacing * 3;
  }
}