/**
 * # Navlist - gets included on multiple navigation bars.
 */

/**
 * ## Imports
 */
 @import 'src/common/styles/variables.scss';
 @import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.navbar-interaction {
    flex: 1 1 80px;

    .navbar-sticky & {
        margin-top: $spacing * 7;
    }
}

.navlist {
    display: flex;
    justify-content: flex-end;
    position: relative;

    &__item {
        /* - Relative positioning for popover child elements. */
        position: relative;

        @media (min-width: 760px) {
            margin-left: $spacing * 11;
        }

        /* - Hide regular menu points until they have enough space
         * to appear. */
        &--menu-point {
            display: none;

            @include minSm {
                display: block;
            }

            &:last-of-type {
                margin-right: $spacing * 8;
            }
        }
    }

    &__link {
        display: block;
        font-size: 16px;
        white-space: nowrap;

        /* - Overwriting react component's `.link` default class. */
        .light-theme & {
            color: rgba($black, .5);
        }

        .dark-theme & {
            color: rgba($white, .5);
        }

        .navbar--dark-background &,
        .navbar-sticky & {
            color:rgba($white, .5)
        }

        &--dropdown {
            padding-right: $spacing * 4;
            position: relative;

            &::after {
                content: "";
                background: $black;
                display: block;
                height: $spacing * 2;
                opacity: .5;
                position: absolute;
                right: 0;
                top: $spacing * 2;
                width: $spacing * 3;
                -webkit-mask: url("/img/homepage_rebrand/icons/arrow-bottom.svg") no-repeat center center;
                mask: url("/img/homepage_rebrand/icons/arrow-bottom.svg") no-repeat center center;

                .dark-theme &,
                .navbar-sticky &,
                .homepage--dark &,
                .navbar--dark-background & {
                    background-color: $white;
                }
            }
        }
    }

    &__menu-button {
        background-color: transparent;
        border: none;

        &:hover {
            cursor: pointer;
        }

        &:focus-visible {
            border-radius: $border-radius-m;
            outline: 2px solid $soft-blue-800;
        }
    }
}

