@import 'src/common/styles/variables.scss';

.rating {
  > span:not(:last-child) {
    margin-right: 1px;
  }

  &--tripadvisor {
    height: 13px;
    min-width: 64px;
  }

  &--yelp {
    height: 16px;
    min-width: 84px;

    .full-icon, .empty-icon {
      width: 16px;
      height: 16px;
    }

    &.rating {
      &--1 {
        .full-icon {
          rect {
            fill: #F2BD79;
          }
        }
      }

      &--2 {
        .full-icon {
          rect {
            fill: #FEC011;
          }
        }
      }

      &--3 {
        .full-icon {
          rect {
            fill: #FF9242;
          }
        }
      }

      &--4 {
        .full-icon {
          rect {
            fill: #F15C4F;
          }
        }
      }

      &--5 {
        .full-icon {
          rect {
            fill: #D32323;
          }
        }
      }
    }
  }
}