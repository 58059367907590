@import 'src/common/styles/variables.scss';

.swiper-component {
  position: relative;

  .swiper-arrow {
    position: absolute;
    top: 50%;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-repeat: no-repeat;
    z-index: 2;
    cursor: pointer;

    @include themify($themes) {
      background-color: themed('backgroundSecondary');
      border: 1px solid themed('strokePrimary');
    }

    &--prev {
      left: 0;
      background-image: url("/common/images/icons/simple-arrow-left.svg");
      background-position: calc(50% - 1px) center;

      .dark-theme & {
        background-image: url("/common/images/icons/dark-simple-arrow-left.svg");
      }
    }

    &--next {
      right: 0;
      background-image: url("/common/images/icons/simple-arrow-right.svg");
      background-position: calc(50% + 1px) center;

      .dark-theme & {
        background-image: url("/common/images/icons/dark-simple-arrow-right.svg");
      }
    }

    &:disabled {
      cursor: initial;
      display: none;
    }
  }
}