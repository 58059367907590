/*
 * Todo kdrechsler: can these styles be deleted?
 */
@import 'src/common/styles/variables.scss';

.react-select {
  font-size: 12px;

  .react-select {
    &__control {
      cursor: pointer;
      box-shadow: none;
      border-color: transparent;
      min-height: auto;
      flex-wrap: nowrap;

      &:hover {
        border-color: $lavender;

        .react-select__single-value {

          @include themify($themes) {
            color: themed('inputTextColor');
          }
        }
      }

      &--is-focused {
        border-color: $lavender;
      }

      &--menu-is-open {
        .react-select {
          &__indicator {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__indicator {
      padding: $spacing;

      svg {
        width: $spacing * 4;

        @include themify($themes) {
          fill: themed('iconPrimary');
        }

        .homepage--dark & {
          fill: $grey-800;
        }
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__single-value {
      position: static;
      top: 0;
      transform: none;
      margin: 0;
      text-overflow: initial;
      overflow: initial;
      max-width: 100%;
      & + input {
        position: absolute;
      }
    }

    &__value-container {
      padding: 0;
      overflow: initial;

      @media (max-width: 1024px) {
        padding: 6px 10px 6px 0;
        white-space: nowrap;
      }
    }

    &__menu {
      margin: 0;
      border: 1px solid $lavender;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
      border-radius: 0;
      position: absolute;

      @include themify($themes) {
        background-color: themed('backgroundSecondary');
      }
    }

    &__option {
      padding: 10px ($spacing * 2);
      cursor: pointer;
      outline: none;

      @include themify($themes) {
        color: themed('textPrimary');
      }

      &:hover {
        @include themify($themes) {
          background-color: themed('backgroundSecondaryHover');
        }
      }

      &:active {
        @include themify($themes) {
          background-color: themed('backgroundSecondaryPressed');
        }
      }
    }

    &__option--is-selected {
      position: relative;
      background-color: transparent;
      font-weight: 700;

      &::after {
        content: '';
        flex-shrink: 0;
        margin-left: $spacing;
        display: block;
        width: 10px;
        height: 14px;
        position: absolute;
        right: $spacing * 3;
        top: $spacing  * 2;
        -webkit-mask:  url("/img/check.svg") no-repeat center center;
        mask: url("/img/check.svg") no-repeat center center;

        @include themify($themes) {
          background-color: themed('strokeFocus');
        }
      }
    }
  }
}