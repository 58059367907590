@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.slider-item {
  border-radius: $border-radius-m;
  box-sizing: border-box;

  &__img-container {
    display: block;
    position: relative;
    text-align: center;
    border-radius: $border-radius-s;
    overflow: hidden;
    height: $spacing * 31;
    max-height: $spacing * 31;
    padding: $spacing * 3;
    background: transparent url("/img/cast-item-image-bg.svg") center center no-repeat;

    &:focus, &:active {
      outline: 0;
    }

    @include minSm {
      height: 143px;
      max-height: 143px;
    }

    @include minMd {
      height: $spacing * 31;
      max-height: $spacing * 31;
    }
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    max-height: 100%;
    max-width: 100%;
  }

  &__body {
    padding: 12px 0 0;
    display: flex;
    flex-direction: column;
    height: calc(100% - 154px);
  }

  &__title {
    display: inline-block;
    margin-bottom: auto;
    font-size: 14px;
    line-height: 17px;

    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  &__text {
    margin-top: 1px;
    font-size: 12px;
    line-height: 14px;

    @include themify($themes) {
      color: themed('textTertiary');
    }
  }
}
