/**
 * # Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * # Variables
 */
$ad-tag-max-width: 100px;

/**
 * # Styles
 */
.search-result-item {
  position: relative;
  font-family: $font-family;
  margin-bottom: 24px;
  //content-visibility: auto;
  padding: $spacing * 4;

  @include themify($themes) {
    background-color: themed('backgroundSecondary');
  }

  @include minSm {
    width: 100%;
    background: none;
    padding: 0;

    .dark-theme & {
      background: none;
    }
  }

  .feedback-button {
    bottom: -25px;
  }

  @media (max-width: 1024px) {
    margin-bottom: $spacing * 8;
  }

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }

  &--ad {
    margin-bottom: 34px;

    @media (max-width: 768px) {
      padding-bottom: 20px;
      margin-bottom: 0;
    }
  }

  &__url-wrapper {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    .icon {
      cursor: pointer;
      height: 16px;
      flex-shrink: 0;

      path {
        transition: all 0.3s ease 0s;

        @include themify($themes) {
          stroke: themed('iconPrimary');
        }
      }

      &:hover,
      &:focus {
        border-radius: 50%;

        @include themify($themes) {
          background: themed('backgroundSecondaryPressed');
          outline: 3px solid themed('backgroundSecondaryPressed');
        }

        path {
          @include themify($themes) {
            stroke: themed('accentPrimary');
          }
        }
      }
    }
  }

  &__top {
    position: relative;
    font-weight: normal;
    display: inline-block;
    margin: 0 0 6px;
    max-width: 100%;
  }

  &__title {
    font-size: 20px;
    line-height: 1;
    vertical-align: sub;

    @extend %link;

    &--ad {
      &:hover {
        text-decoration: none;
      }

      @include themify($themes) {
        color: themed('link');
      }
    }
  }

  &__text-label {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;

    &--search {
      line-height: 1.25;
      @extend %ellipsis;
      display: block;

      @include maxSm {
        white-space: pre-wrap;
        @include truncate(2);
      }
    }
  }

  &__text-body {
    line-height: 1.25;

    .search-result-item__title:hover & {
      text-decoration: underline;
    }
  }

  &__ad-tag {
    display: inline-block;
    font-size: 10px;
    line-height: 1;
    padding: 1px 3px 0;
    border: 1px solid $blue;
    border-radius: 3px;
    text-decoration: none;
    transform: translateY(-4px);
    max-width: $ad-tag-max-width;

    @include themify($themes) {
      color: themed('link');
    }

    .dark-theme & {
      border-color: $blue-light;
    }

    .search-result__aside & {
      transform: translateY(-3px);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    margin: 0 0 1px;
  }

  &__img {
    width: 16px;
    height: 16px;
    margin: 0 4px 0 0;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      display: inline-block;
      object-fit: cover;
      position: absolute;
    }
  }

  &__favicon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    @include minSm {
      position: absolute;
      left: -24px;
      top: calc(50% - 2px);
      transform: translateY(-50%);

      .pc & {
        top: calc(50% - 1px);
      }
    }
  }

  &__url {
    max-width: 680px;
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include themify($themes) {
      color: themed('textTertiary');
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    @include truncate(3);

    @include themify($themes) {
      color: themed('textSecondary');
    }

    p {
      margin: 0;
    }
  }

  &__text--sidebar-ad-or-mobile {
    @include truncate(4);
  }

  &__result {

    font-size: 12px;
    line-height: 12px;

    @include themify($themes) {
      color: themed('textTertiary');
    }

    @include maxSm {
      display: none;
    }
  }

  &__spellcheck {
    margin-bottom: $spacing * 6;
    width: 100%;

    @include maxSm {
      padding: 16px;
      margin-bottom: 0;

      @include themify($themes) {
        background-color: themed('backgroundSecondary');
      }
    }
  }

  &__spellcheck-title {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: $spacing;

    &__spellcheck-title-body {
      font-size: 18px;
      line-height: 21px;
    }

    .dark-theme & {
      color: $white;
    }
  ;
  }

  &__spellcheck-title-link {
    display: inline-block;
    font-size: 18px;
    line-height: 21px;
    font-style: italic;

    @include themify($themes) {
      color: themed('link');
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__spellcheck-posttitle {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;

    .dark-theme & {
      color: $white;
    }

    span {
      display: inline-block;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__spellcheck-posttitle-link {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;

    @include themify($themes) {
      color: themed('link');
    }
  }
}

.privacyCard {
  position: relative;
  height: $spacing * 4;
  margin-top: $spacing * -1;
  margin-left: 7px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: ($spacing * 6);
    height: ($spacing * 6);
    border-radius: 50%;
    opacity: 0.1;
    z-index: -1;
  }

  @media (max-width: 600px) {
    margin-bottom: 1px;
  }
}

.deepLink-snippet {
  display: inline-block;
  margin-top: $spacing * 2;
  vertical-align: top;

  .search-result-item {
    &__title {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 16px;
    }
  }

  .deepLink-snippet {
    &__dot {
      display: inline-block;
      background: $dark-grey;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      margin: 0 5px;
    }
  }

  .search-news-item {
    &__title {
      margin: 0;
      transform: none;
      width: auto;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.deepLink {
  display: inline-block;
  margin-top: $spacing * 3;
  vertical-align: top;

  .search-result-item {
    &__text {
      font-size: 14px;
      line-height: 20px;
      max-width: 308px;

      @include maxSm {
        display: none;
      }
    }
  }

  .search-result-item {
    &__title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 4px;
      transform: none;
      width: auto;

      @include maxSm {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }

  @include maxSm {
    width: calc(100% - 22px);
    margin-left: 0;

    &:not(:last-child) {
      border-bottom: 1px solid $lavender;
      padding-bottom: $spacing * 2;

      .dark-theme & {
        border-color: $asphalt-grey;
      }
    }
  }

  @include minSm {
    width: calc(50% - 21px);

    &:nth-of-type(odd) {
      margin-left: 22px;
      margin-right: 14px;
    }
  }
}

.search-result {
  &__main {
    .simple-slider-widget {
      &--ads {
        margin-bottom: 32px;

        @media (max-width: 768px) {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__aside {
    .search-result-item {
      &__title {
        font-size: 16px;
      }

      &__url {
        font-size: 13px;
      }

      &__text {
        font-size: 12px;
      }
    }
  }
}

.feedback-control {
  text-align: right;
  margin-bottom: 6px;
}
