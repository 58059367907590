/**
 * # Layout
 */

/**
 * ## Variables
 */
$header-height-mobile: 147px;
$header-height-narrow: 127px;
$feedback-box-height: 146px;

/**
 * ## Styles
 */
.main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-height: calc(100vh - $header-height-mobile - $feedback-box-height);
  position: relative;
  /* When altering this `z-index` test for correct
   * overlap of the main navigation's search suggestions.
   */
  z-index: 2;

  @include themify($themes) {
    background-color: themed('backgroundSecondary');

    @media (min-width: $image-results-layout-break-point) {
      background-color: transparent;
    }
  }

  @media (min-width: 760px) {
    min-height: calc(100vh - $header-height-narrow - $feedback-box-height);
  }

  @media (min-width: 1102px) {
    min-height: calc(100vh - $header-height-narrow);
  }
}
