@import '../../../styles/variables';
@import '../../../styles/utilities';

.single-business-card {
  .swipeable-component & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include themify($themes) {
      border: none;
    }
  }

  &__close-icon {
    position: absolute;
    z-index: 1;
    right: $spacing * 4;
    top: $spacing * 4;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: rgba($black, 0.4);
    border-radius: 50%;
    color: $grey-100
  }

  &__top {
    display: flex;
    justify-content: space-between;
    gap: $spacing;
    margin-bottom: $spacing * 5;

    @include minSm {
      flex-direction: column;
    }

    .swipeable-component & {
      flex-direction: column;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include minSm {
      order: 1;
      display: block;
    }

    .swipeable-component & {
      order: 1;
      display: block;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin: 0 0 3px;
    word-break: break-word;
    padding-right: 25px;

    @include themify($themes) {
      color: themed('textPrimary');
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__status {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    margin-top: auto;

    &--closed {
      @include themify($themes) {
        color: themed('systemPrimary');
      }
    }

    &--open {
      @include themify($themes) {
        color: themed('systemTertiary');
      }
    }
  }

  &__img-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    border-radius: $border-radius-s;
    overflow: hidden;
    margin: 0;

    @include minSm {
      justify-content: space-between;
      margin: -19px -15px ($spacing * 4);
      border-radius: $border-radius-xl $border-radius-xl 0 0;
    }

    .swipeable-component & {
      justify-content: space-between;
      margin: -19px -15px ($spacing * 4);
      border-radius: $border-radius-xl $border-radius-xl 0 0;
    }
  }

  &__img {
    height: 140px;
    width: 140px;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;

    @include minMd {
      height: 120px;
      width: 120px;
    }

    @include minMdAside {
      height: 140px;
      width: 140px;
    }

    &--3 {
      @include minSm {
        max-width: calc(100% / 3);
      }

      .swipeable-component & {
        max-width: calc(100% / 3);
      }
    }
  }

  &__img-text {
    position: absolute;
    bottom: $spacing * 2;
    left: $spacing * 2;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $white;
    max-width: 70%;

    @include minSm {
      left: auto;
      right: $spacing * 2;
    }

    .swipeable-component & {
      left: auto;
      right: $spacing * 2;
    }
  }

  &__block {
    &:not(:last-child) {
      margin-bottom: $spacing * 2;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    padding: ($spacing * 4) 0;
  }

  &__button {
    border: 1px solid transparent;
    background: none;
    width: 100%;
    cursor: pointer;
    padding: 0;
    text-align: center;

    &:hover {
      text-decoration: none;
    }

    @include themify($themes) {
      color: themed('accentPrimary');
    }

    &:not(:last-child) {
      @include themify($themes) {
        border-right-color: themed('strokePrimary');
      }
    }
  }

  &__button-text {
    display: block;
    margin-top: 7px;
    font-family: $font-family;
    font-size: 14px;
    line-height: 1;

    @include themify($themes) {
      color: themed('textTertiary');
    }
  }

  &__contacts {
    padding: $spacing ($spacing * 4);
  }

  &__contacts-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: $spacing * 3;
    padding: ($spacing * 3) 0;
    border-bottom: 1px solid $lavender;

    @include themify($themes) {
      border-color: themed('strokePrimary');
    }

    &:last-child {
      border: none;
    }

    &--schedule {
      .schedule {
        width: 100%;

        &__top {
          @include minSm {
            justify-content: flex-end;
          }

          .swipeable-component & {
            justify-content: flex-end;
          }
        }

        &__title {
          @include minSm {
            display: none;
          }
        }

        &__time {
          display: flex;
          justify-content: space-between;

          @include minSm {
            justify-content: flex-end;
            text-align: end;
          }

          .swipeable-component & {
            justify-content: flex-end;
            text-align: end;
          }
        }
      }
    }
  }

  &__contacts-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    @include themify($themes) {
      color: themed('textPrimary');
    }

    .single-business-card__contacts-row--schedule & {
      align-self: flex-start;
    }
  }

  &__contacts-link {
    font-size: 14px;
    line-height: 20px;
    font-style: normal;

    @extend %link;
  }

  &__contacts-text {
    font-size: 14px;
    line-height: 20px;
    font-style: normal;

    @include themify($themes) {
      color: themed('textPrimary');
    }

    &--fake-link {
      cursor: pointer;

      @include themify($themes) {
        color: themed('link');
      }

      &:visited {
        @include themify($themes) {
          color: themed('linkVisited');
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__contacts-value {
    margin: 0;
    text-align: right;
  }

  &__reviews {
    padding: $spacing * 4;
  }

  &__reviews-title {
    margin: 0 0 ($spacing * 3);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  &__review-top {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
  }

  &__review-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 27px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: $spacing * 2;
  }

  &__review-img {
    width: 27px;
    height: 27px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  &__review-rating {
    margin-right: $spacing * 2;
  }

  &__review-date {
    font-size: 14px;
    margin-top: 3px;

    @include themify($themes) {
      color: themed('textTertiary');
    }
  }

  &__review-text-wrapper {
    margin: 0;
  }

  &__review-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin: 0 0 ($spacing * 3);
    @include truncate(2);

    @include themify($themes) {
      color: themed('textSecondary');
    }
  }

  &__review-button {
    @extend %button-as-link;
    font-size: 14px;
    line-height: 16px;
  }

  .map-modal {
    &__trigger-button {
      display: none;
    }
  }
}
