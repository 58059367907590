@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.button-cta {
  border: 1px solid $lavender;
  border-radius: $spacing * 5;
  cursor: pointer;
  height: 30px;
  line-height: 16px; // all browsers use FF's ever unchanging value
  margin-bottom: $spacing * 3;
  padding: 7px 14px 6px 15px;
  background-clip: border-box;

  @include themify($themes) {
    color: themed('link');
  }

  &--light {
    @include themify($themes) {
      background: themed('backgroundSecondary');
      border-color: themed('strokePrimary');
    }
  }

  @extend %button;

  .widget-title & {
    margin-top: -1px;
  }

  &__label {
    padding-right: 15px;
    position: relative;
    width: 100%;

    @extend %ellipsis;

    &::after {
      background-color: $blue;
      content: "";
      display: inline-block;
      height: $spacing * 4;
      position: absolute;
      right: -1px;
      top: -1px;
      width: $spacing * 2;

      -webkit-mask:  url("/common/images/icons/chevron.svg") no-repeat center center / 5px 9px;
      mask: url("/common/images/icons/chevron.svg") no-repeat center center / 5px 9px;

      .dark-theme & {
        background-color: $blue-light;
      }
    }
  }

  &--add-to-chrome {
    @include maxMd {
      display: none;
    }
  }
}

/**
 * Overwriting Firefox stubborn user agent styles.
 */
@supports (-moz-user-focus:none) {
  .button-cta {
    line-height: 16px;
    //padding: 7px 13px 6px ($spacing * 4);
    padding-top: 6px;
  }
}