.button {
  width: 100%;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: 0.25s all ease-out;

  &:hover, &:focus {
    outline: none;
  }
}

button:focus {
  outline: none;
}
