@import 'src/common/styles/variables.scss';

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.input {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  transition: 0.25s ease-out box-shadow;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 15px 92px 13px 16px;
  border: 0;
  border-radius: $border-radius-m;
  outline: none;
  -webkit-appearance: none;

  @include themify($themes) {
    color: themed('textPrimary');
  }

  /**
   * Hack only for PC systems
   */
  .pc & {
    padding-top: 14px;
    padding-bottom: 14px;

    /**
     * Attention: Firefox/PC related hack
     * located in own selector down below.
     */
  }

  @include themify($themes) {
    background-color: themed('backgroundSecondary');
  }

  &::placeholder {
    @include themify($themes) {
      color: themed('textTertiary');
    }
  }
}

@supports (-moz-user-focus:none) {
  .input {
    padding: 15px 92px 13px 16px;
  }
}
