@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Typography
 */
@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Semi Bold";
  src: url("../../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Light";
  src: url("../../fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Thin";
  src: url("../../fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

/**
 * ## Placeholders/Mixins
 */
 %widget-image {
  left: 25%;
  height: auto;
  max-width: 59%;
  position: absolute;
  top: 36%;
  transform: translateX(-50%);
  z-index: 3;

  @include minXsm {
      top: 40%;
  }
}

/**
 * ## Logo
 */
.company-logo-wrapper {
  text-align: center;
  width: 100%;
  align-self: flex-end;

  /* - Logo inside company pages's header: */
  .navbar-sticky__logowrapper & {
    text-align: left;
    width: 176px;
  }

  /* - Logo inside search result page's header: */
  .header__main & {
    height: auto;
    margin: ($spacing * 4) 0 ($spacing * 3) -5px;
    width: 120px;

    @include minSm {
      margin: $spacing 0 $spacing -7px;
      width: 146px;
    }
  }

  /* - Logo inside search result page's FIXED header: */
  .header.fixed & {
    height: auto;
    margin-left: -4px;
    width: 110px;
  }

  /* - Logo inside Footer: */
  .pagefooter & {
    height: auto;
    max-width: 137px;
  }
}

.logo-svg {
  height: auto;
  width: 100%;

  /* - Logo on Homepage above search input: */
  .fixed-half & {
    margin-bottom: 29px;
    width: auto;

    @include minSm {
        height: auto;
        transform: scale(1);
        width: 273px;
    }
  }

  .dark-theme &,
  &--bright {
    .path-typography {
      fill: $white;
    }
  }
}

/**
 * This class on the `<html>` tag is set via JavaScript.
 * It serves the purpose of faking a visual prolonging
 * of the `PageFooter` component's dark background.
 */
.interior-page {
  background: $marketing-blue-black-500;
}

.homepage {
  background-color: $white;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  height: 100%;

  .dark-theme & {
    background: $titanium-700;
  }

  &__filler {
    height: 100%;
  }

  /**
   * Tempest pride. 🏳️‍🌈
   */
  .tempest-wrapper-pride {
    display: inline-block;
    position: relative;
  }

  .tempest-logo-pride {
    display: block;
    height: auto;
    margin-bottom: 29px;
    width: 273px;
    transform: scale(.838);

    @include minSm {
      height: auto;
      transform: scale(1);
      width: 273px;
    }
  }

  &__slide-teaser {
    background: linear-gradient(51.14deg, #6081F5 -11.73%, #5341C5 57.09%, #3D2F99 102.08%);
    overflow: hidden;
    padding: $border-radius-xxxxl 0 0;

    @include minSm {
        padding-left: $spacing * 12;
        padding-right: $spacing * 12;
    }

    .slide-container__list {
      @include minSm {
        margin: 46px 0 0 38px;
      }
    }
  }
}

.fixed-half {
  background: $white;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 70vh;
  /* -60px to substract the height of `.navbar` : */
  height: calc(100% - 60px);
  z-index: 1;

  @include minSm {
    min-height: 100vh;
    position: fixed;
  }

  /* - Keeping the menu and suggestions working when they're used. */
  .menu-open & {
    z-index: 2;
  }

  .dark-theme & {
    background: $titanium-700;
  }

  /**
   * This class `fixed-half--scrolled` is set via JavaScript on scroll.
   * It serves the purpose of faking a visual prolonging
   * of the `PageFooter` component's dark background.
   */
  &.fixed-half--scrolled {
    background-color: $marketing-blue-black-500;
  }

  &__body {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    justify-items: center;
    height: 100%;
    position: relative;

    &__wrapper {
      width: 100%;
      padding: 0 ($spacing * 5);
      position: absolute;
      // 221px - is 70px of menu height + 141px total height of main Logo and Search form height
      top: calc(50% - 211px);
    }
  }
}

.scrolling-half {
  background-color: transparent;
  margin-top: -71px;
  position: relative;
  z-index: 2;

  @include minSm {
    margin-top: calc(100vh - 131px);
  }

  &--theme-tempest {
    margin-top: -98px;

    @include minSm {
      margin-top: calc(100vh - 128px);
    }
  }

  /* - Keeping the menu and suggestions completely visible, meaning
   * not cut off, even on mobile devices:
   */
  .search-suggestions-open & {
    z-index: 0;
  }
}

/**
 * - Menu wrapper outside
 * a helping container outside of `.fixed-half`/`.scrolling-half` to
 * mimic the menu's position while keeping it out of trouble
 * with overlaps caused by these containers.
 */
.menu-wrapper-outside {
  position: fixed;
  top: 0;
  height: 0;
  width: 100%;
  z-index: $z-index-header-settings;

  @media (min-width: 700px) {
    max-width: 1220px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
  }

  .menu__button {
    display: none;
  }

  .menu__body {
    top: 0;
    overflow: hidden;
    overflow-y: auto;

    @media (min-width: 700px) {
      top: ($spacing * 7);
    }
  }
}

/**
 * - This class displays a background layer to cover the rest
 * of the page when the menu is open.
 */
@mixin menu-background-layer($z-index: $z-index-header-default - 1) {
  &::before {
    @media (max-width: 699px) {
      content: "";
      height: 100vh;
      left: 0;
      width: 100vw;
      position: fixed;
      top: 0;
      z-index: $z-index-header-default - 1;

      @include themify($themes) {
        background: themed('transparentLayer');
      }
    }
  }
}


.menu-open .homepage,
.sticky-menu-open .simple-header__menu {
  @include menu-background-layer;
}

.menu-open .header__inner {
  @include menu-background-layer($z-index: 2);
}

.home-centerwrapper,
.home-centerwrapper-wide,
.home-centerwrapper-narrow {
  margin: 0 auto;
  padding: 0 ($spacing * 6);

  @include minMd {
    padding-left: $spacing * 13;
    padding-right: $spacing * 13;
  }
}

.home-centerwrapper {
  max-width: $screen-max-md-aside;
}

.home-centerwrapper-wide {
  max-width: $screen-max-md-aside + ($spacing * 13);

  .teaser-headline-decoration--search-page &,
  .teaser-benefits & {
    padding-right: 0;
    max-width: 1440px;

    & .row__left {
        @media (min-width: 769px) {
            width: 45%;
        }
    }

    & .row__right {
        @media (min-width: 769px) {
            width: 55%;
        }
    }
  }
}

.home-centerwrapper-narrow {
  max-width: 900px;

  &--mobile-color-swap {
    @include maxSm {
      background-color: $marketing-blue-500;
    }
  }

  &--line-merge {
    @include maxSm {
      margin-top: -300px;
      padding-top: 250px;
    }
  }
}

/**
 * ### Privacy Policy
 */
 .home-legal-page {
  color: rgba($white, .6);
  overflow: hidden;
}

/**
 * ## Typography
 */
.home-text-centered {
  margin-bottom: $spacing * 10;
  text-align: center;

  &--divider-top {
    @include minMd {
      position: relative;
      margin-top: $spacing * 20;
      padding-top: $spacing * 10;
    }

    &::before {
      @include minMd {
        background-color: rgba($white, .1);
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        max-width: 590px;
        top: 0;
        width: calc(100% - ($spacing * 8));
        @include center-x(absolute);
      }
    }
  }
}

.homepage-headline-big {
  @extend %homepage-teaser-subheadline;

  .teaser-headline-image & {
    margin: ($spacing * 20) auto ($spacing * 4);
    max-width: 600px;
    text-align: center;
  }

  .about & {
    margin: 50px 0 30px;

    @include minSm {
        margin: 90px 0 50px;
    }
  }

  .pagefooter & {
    margin: 14px 0 ($spacing * 7);
  }

  &--no-change {
    font-size: 44px;
    line-height: 62px;
  }
}

.homepage-headline-tiny {
  @extend %homepage-teaser-tiny-headline;
}

.homepage-paragraph {
  @extend %homepage-teaser-paragraph;
}

.homepage-strong {
  @extend %homepage-teaser-strong;
}

.home-indent-left {
  @include minMd {
    padding-left: 102px;
  }
}

.portrait {
  text-decoration: underline;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.homepage-headline {
  color: $white;
  font-family: 'Poppins Light';
  font-size: 32px;
  line-height: 48px;
  padding-bottom: $spacing * 1;

  @include minSm {
      font-size: 44px;
      line-height: 58px;
  }

  &__separator {
      border-bottom: 1px solid rgba($white, .1);
  }
}

.paragraph-incognito {
  &--all-caps {
      text-transform: uppercase;
  }

  a {
      color: inherit;
      text-decoration: underline;
  }
}

.paragraph {
  @extend %standard-typographic-format;
}

.typographic-list {
  @extend %standard-typographic-format;
  list-style-type: unset;
  margin-bottom: $spacing * 5;

  padding-left: $spacing * 6;

  &--ordered {
      list-style-type: decimal;
  }

  &--no-indention.typographic-list--ordered {
      counter-reset: section;
      list-style-position: inside;
      list-style-type: none;
      padding-left: 0;
  }

  .typographic-list {
      margin-bottom: 0;
  }

  &__headline {
      @extend %standard-typographic-format;
      display: flex;
      font-style: italic;
      line-height: 1.5;
      margin: ($spacing * 8) 0 ($spacing * 2) ($spacing * 6);

      &::before {
          counter-increment: section;
          content: counters(section, ".") ".";
          display: inline-block;
          font-style: italic;
          margin-right: $spacing;
      }
  }
}

.strong {
  font-family: "Poppins Semi Bold";
}

.blockquote {
  margin: 0 ($spacing * 4);

  @include minSm {
      margin-left: $spacing * 8;
      margin-right: $spacing * 8;
  }

  & > .paragraph:first-of-type {
      margin-top: 0;
  }
}

.copytext-link {
  color: $cobalt-100;
  text-decoration: underline;

  &:hover {
      text-decoration: none;
  }
}

.copytext-subheadline {
  margin-bottom: 0;
}

/**
 * Todo: find out if this is the right place to
 * reset basic element styles.
 */
abbr {
  text-decoration: none;
}

/**
* ## Styles
*/
/**
* ### Animations
*/
@keyframes animatedgradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.content-teaser {
  background: radial-gradient(
      50% 50% at 50% 50%,
      #0F0F26 0%,
      #1D1D47 0.01%,
      #0F0F26 100%
  );
  background-repeat: no-repeat;
  border-bottom-left-radius: $border-radius-xxxl;
  border-bottom-right-radius: $border-radius-xxxl;
  box-shadow: 0px 14px 46px -18px rgb(0 0 0 / 75%);
  color: $white;
  margin-bottom: -$border-radius-xxxl;
  position: relative;
  z-index: 2;

  @include minSm {
      border-bottom-left-radius: $border-radius-xxxxl;
      border-bottom-right-radius: $border-radius-xxxxl;
      margin-bottom: -$border-radius-xxxxl;
  }

  @include minMd {
      background:
          url("/img/homepage_rebrand/ornament-01.svg") no-repeat right -2px top -25px,
          radial-gradient(
              50% 50% at 50% 50%,
              #0F0F26 0%,
              #1D1D47 0.01%,
              #0F0F26 100%
          )
      ;
  }

  &__body {
      /* Avoids horizontal scroll caused by `::after` background image. */
      //overflow: hidden;
      position: relative;

      @include minSm {
          padding: ($spacing * 34) ($spacing * 12) ($spacing * 6);
      }
  }

  &__section {
      margin: 0 auto;
      max-width: $screen-max-md-aside;

      @include minMd {
          padding: 0 ($spacing * 13);
      }

      &--skewed {
          @include minMd {
              padding: 0 ($spacing * 54);
          }
      }

      &--shifted {
          @include minMd {
              padding: 0 ($spacing * 66);
          }
      }

      &--medium {
          padding: $spacing * 6;

          @include minSm {
              padding: $spacing * 10;
          }
      }

      &--division {
          margin-bottom: $spacing * 10;
          padding-bottom: $spacing * 20;
          position: relative;

          @include minMd {
              margin-bottom: $spacing * 29;
          }

          &::after {
              background-color: rgba($white, .1);
              bottom: 0;
              content: "";
              display: block;
              height: 1px;
              left: 50%;
              transform: translateX(-50%);
              position: absolute;
              width: 100%;
          }
      }

      &--wide {
          @include minMd {
              padding: 0;
          }
      }
  }

  &__headline {
      @extend %homepage-teaser-headline-allcaps;
      margin-bottom: $spacing * 4;
      padding-top: $spacing * 25;
      line-height: 22px;

      @include minSm {
          padding-top: $spacing;
          line-height: 30px;
      }
  }

  &__subheadline {
      @extend %homepage-teaser-subheadline;

      &--long-text {
          font-family: "Poppins Light", Helvetica, Arial;
          font-size: 23px;
          line-height: 34.5px;

          @include minSm {
              font-size: 40px;
              line-height: 60px;
          }
      }
  }

  &__cta-row {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing * 10;
      justify-content: space-between;
      width: 100%;

      @include minMd {
          flex-wrap: nowrap;
      }

      &--only-right {
          @include minMd {
              justify-content: flex-end;
          }
      }
  }

  &__cta {
      @include maxMd {
          order: 2;
      }

      @include minMd {
          width: 50%;
      }

      &--browse {
          @include minSm {
              padding: ($spacing * 5) 10% 0 0;
          }
      }
  }

  /**
   * ### Teaser Typography
   */
  &__paragraph {
      @extend %homepage-teaser-paragraph;
      line-height: 30px;

      @include minMd {
          max-width: 488px;
      }

      .home-text-centered & {
          margin: 0 auto ($spacing * 4);
      }
  }

  &__link {
      color: $cobalt-100;
      text-decoration: underline;

      &:hover {
          text-decoration: none;
      }
  }

  &__link-list {
      align-items: center;
      display: flex;
      gap: $spacing * 6;
      margin-bottom: $spacing * 6;

      @media (max-width: 380px) {
          flex-wrap: wrap;
      }

      @include minSm {
          margin-bottom: $spacing * 20;
      }
  }

  &__button-install {
      margin: ($spacing * 6) 0;
  }

  &__link-learn {
      color: $white;
  }

  /**
   * ### Teaser Visual
   */
  &__visual {
      margin: 0 auto;
      max-width: 100%;
      padding-left: 16%;
      position: relative;

      @include minMd {
          order: 1;
          margin: (-$spacing * 10) (-$spacing * 15) ($spacing * 26) 0;
          padding-left: 6%;
      }

      &::before {
          /* - Todo: align colors with designers and new color system. */
          box-shadow: 0 0 170px 60px rgb(#90cfbb, .8);
          content: "";
          height: 0;
          pointer-events: none;
          position: absolute;
          right: 126px;
          top: $spacing * 30;
          transform: skew(180deg, 0deg) translateX(-50%);
          transition: box-shadow 1s;
          width: 120px;
          z-index: 0;
      }

      &.content-teaser__visual--locked {
          &::before {
              /* - Todo: align colors with designers and new color system. */
              box-shadow: 0 0 170px 60px rgb(#b33f24, 1);
          }
      }
  }

  &__visual-image {
      height: auto;
      left: 50%;
      max-width: 100%;
      position: relative;
      transform: translateX(-50%);
      z-index: 2;
  }

  &__visual-widget {
      @extend %widget-image;
      opacity: 1;

      .content-teaser__visual--locked & {
          opacity: 0;
      }
  }

  &__visual-widget-unlocked {
      @extend %widget-image;
      opacity: 0;

      .content-teaser__visual--locked & {
          opacity: 1;
      }
  }

  &__icon-wrapper {
      animation: animatedgradient 3s ease infinite;
      /* Todo: replace these colors with design system colors. */
      background: linear-gradient(
          76deg, rgba(26, 249, 166, 0.4) 0.04%,
          #19E4B3 31.18%, #117BF5 109.98%
      );
      background-size: 300% 300%;
      border-radius: $spacing * 5;
      display: block;
      height: 72px;
      left: 50%;
      overflow: hidden;
      position: relative;
      top: $spacing * 8;
      transform: translateX(-50%);
      width: 72px;
      z-index: 4;

      @include minXsm {
          border-radius: $spacing * 7;
          height: 108px;
          width: 108px;
          top: 60px;
      }

      .content-teaser__visual--locked & {
          background: linear-gradient(
              76deg, rgb(228, 25, 25),
              rgb(206, 106, 154)
          );
      }
  }

  &__icon-body {
      background-color: $lightest-black;
      border-radius: $spacing * 5;
      float: left;
      height: calc(100% - 4px);
      justify-content: center;
      margin: 2px;
      position: relative;
      width: calc(100% - 4px);

      @include minXsm {
          border-radius: $spacing * 7;
      }
  }

  &__icon {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: opacity .3s ease-in;

      &--locked {
          height: 21px;
          width: 21px;

          @include minXsm {
              height: auto;
              width: auto;
          }

          .content-teaser__visual:not(.content-teaser__visual--locked) & {
              opacity: 0;
          }
      }

      &--open {
          height: 24px;
          width: 21px;

          @include minXsm {
              height: auto;
              width: auto;
          }

          .content-teaser__visual--locked & {
              opacity: 0;
          }
      }
  }
}