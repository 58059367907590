@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.popup-box {
  position: relative;
  max-width: 375px;
  padding: ($spacing * 6) ($spacing * 6) 0;
  @extend %popup-basics;
  @include center-xy;

  &__close-button {
    position: absolute;
    top: 18px;
    right: 18px;
    padding: 0;
    width: auto;
    border: none;

    @include themify($themes) {
      color: themed('iconPrimary');
    }
  }

  &__inner {
    max-height: 90vh;
    overflow-y: auto;

    @media (max-height: 765px) {
      margin-top: 15px;
      padding-right: 5px;
      padding-bottom: 24px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 23px;

    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 18px;
    color: $text;
    margin-bottom: 16px;

    .dark-theme & {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &__radio-list {
    border-radius: 8px;
    padding: 1px 16px;
    margin-bottom: 24px;

    @include themify($themes) {
      background: themed('modalPrimary');
      border: 1px solid themed('modalStrokePrimary');
    }
  }

  &__radio-list-item {
    padding: 15px 0;

    &:not(:last-child) {
      @include themify($themes) {
        border-bottom: 1px solid themed('modalStrokeSecondary');
      }
    }
  }

  &__textarea {
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 27px;
    resize: none;
    width: 100%;
    height: 86px;
    font-size: 16px;
    line-height: 20px;
    color: #272d4a;
    outline: none;

    @include themify($themes) {
      background: themed('modalPrimary');
      border: 1px solid themed('modalStrokePrimary');
    }

    .dark-theme & {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &__checkbox {
    align-items: flex-start;
    margin-bottom: 22px;

    .check {
      &__label-text {
        font-size: 16px;
        line-height: 21px;
        max-width: initial;
      }
    }
  }

  &__button {
    border-radius: 8px;
    border: none;
    padding: 16px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: $spacing * 5;
    color: $white;

    &:disabled {
      color: #9497A6;
      cursor: auto;

      @include themify($themes) {
        background-color: themed('accentPrimaryDisabled');
      }
    }

    @include themify($themes) {
      background-color: themed('accentPrimary');
    }

    .dark-theme & {
      &:disabled {
        color: #787880;
      }
    }
  }
}
