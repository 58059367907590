@import '../../styles/variables';
@import '../../styles/utilities';

.schedule {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 16px;

  .dark-theme & {
    color: $primary-color-dark-theme;
  }

  &__top {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    transition: 0.25s all ease-out;
    font-family: $font-family;

    @include themify($themes) {
      color: themed('textSecondary');
    }

    &:hover, &:focus {
      outline: none;
    }

    &--with-title {
      justify-content: space-between;
    }
  }

  .my-collapsible {
    .swipeable-component & {
        pointer-events: none;
    }
  }

  .my-collapsible__content-inner {
    margin-top: 10px;
  }

  &__title {
    margin-right: auto;
    font-weight: 700;

    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  &__status {
    &--closed {
      @include themify($themes) {
        color: themed('systemPrimary');
      }
    }

    &--open {
      @include themify($themes) {
        color: themed('systemTertiary');
      }
    }
  }

  &__separator {
    line-height: 1;
    padding-left: 14px;
    position: relative;

    &::before {
      border-radius: 50%;
      content: "";
      display: block;
      font-size: 10px;
      height: 2px;
      left: 6px;
      line-height: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;

      @include themify($themes) {
        background-color: themed('textSecondary');
      }
    }

    &.business-card__phone::before {
      top: 3px;
    }
  }

  &__arrow-icon {
    margin-left: 4px;

    @include themify($themes) {
      color: themed('iconPrimary');
    }

    .swipeable-component & {
      @include themify($themes) {
        color: transparent;
      }
    }

    &--up {
      transform: rotate(180deg);
    }
  }

  &__item {
    list-style: none;
    margin: 0;
    padding-right: 15px;

    &:not(:last-child) {
      margin-bottom: $spacing * 2;
    }

    &--current {
      font-weight: bold;
    }
  }

  &__day {
    display: inline-block;
    min-width: 50px;
    text-align: left;
    font-size: 14px;

    @include themify($themes) {
      stroke: themed('textPrimary');
    }
  }

  &__interval-hours {
    display: flex;
    flex-direction: column;
  }

  &__ampm {
    width: 145px;

    &__time {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__start, &__end {
        width: 61px;
        text-align: right;
      }
    }
  }

  &__abbr {
    text-decoration: none;
  }
}
