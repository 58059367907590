@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.business-brief {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
  width: 100%;

  @include minSm {
    flex-wrap: nowrap;
  }

  &.vertical-view {
    flex-wrap: wrap;
    }

  &__rating-icon {
    margin-right: $spacing * 2;
    min-width: 27px;
    color: #34E0A1;
  }

  &__rating {
    display: inline-block;
    margin-right: $spacing * 2;
    margin-bottom: 2px;
  }

  &__item {
    max-width: 100%;

    &--line-float {
      width: 100%;

      @include minSm {
        width: auto;
      }
    }
  }

  &__reviews,
  &__price-sign,
  &__category {
    display: inline-block;
    white-space: nowrap;
    font-size: 14px;
    line-height: 15px;

    @include themify($themes) {
      color: themed('textSecondary');
    }
  }

  &__reviews {
    text-transform: lowercase;
  }

  &__category {
    display: inline;
    white-space: normal;

    &--cut {
      white-space: nowrap;

      @extend %ellipsis;
    }
  }

  &__category-tooltip {
    margin-left: $spacing;
  }

  &__add-info {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin-top: 3px;

    &--full {
      display: inline;
    }

    .business-brief {
      &__price-sign {
        display: inline-block;
      }
    }
  }

  &__separator {
    padding-left: $spacing * 4;
    position: relative;

    &::before {
      border-radius: 50%;
      content: "";
      display: block;
      font-size: 10px;
      height: 3px;
      width: 3px;
      left: 6px;
      line-height: 1;
      position: absolute;
      top: 5px;

      @include themify($themes) {
        background-color: themed('textTertiary');
      }
    }
  }
}