/**
 * # Back to Search Button
 */


/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';

/**
 * ## Styles
 */
.back-to-search {
    background-color: $white;
    border: none;
    border-radius: $spacing * 10;
    box-shadow: 0 0.5rem 1rem rgba($black, .15);
    color: rgba($black, .5);
    display: inline-flex;
    font-size: 16px;
    height: 55px;
    left: 20px;
    max-width: 55px;
    padding: 0;
    position: fixed;
    text-align: left;
    bottom: 79px;
    transition: max-width .25s ease-out, padding .25s ease-out;
    z-index: 4;

    &:hover {
        cursor: pointer;
        padding: 0 ($spacing * 12) 0 ($spacing * 4);
        transition: max-width .25s ease-in, padding .25s ease-in;
        max-width: 400px;
    }

    &::after {
        background: transparent url("/img/homepage_rebrand/icons/search.svg") center center no-repeat;
        content: "";
        display: block;
        height: 40px;
        position: absolute;
        right: $spacing * 2;
        top: 6px;
        width: 40px;
    }

    .menu-open &,
    .sticky-menu-open &,
    .search-suggestions-open & {
        display: none;
    }

    &__body {
        display: block;
        flex: 0;
        opacity: 0;
        overflow: hidden;
        padding-top: 18px;
        transition: all 0.125s ease-out;
        white-space: nowrap;

        .back-to-search:hover & {
            flex: 1;
            opacity: 1;
            width: auto;
        }
    }
}