@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';
/* Кнопка для вызова карточки */
button.infocard-details{
  padding: 0;
}

.infocard-details {
  position: absolute;
  left: -6px;
  bottom: -31px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  border: none;
  padding: 6px;
  margin: 0;
  width: initial;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  text-decoration: none;

  @include themify($themes) {
    color: themed('textTertiary');
  }

  @media (max-width: 1024px) {
    left: 14px;
  }

  &__icon {
    margin-right: 5px;

    @include themify($themes) {
      stroke: themed('iconPrimary');
    }
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

/* Карточка */

.infocard-details-card {
  max-height: 80vh;
  overflow-y: auto;

  @media (max-height: 545px) {
    margin-top: 17px;
    padding-right: 5px;
    padding-left: 5px;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 ($spacing * 4);

    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  &__text {
    border-radius: 8px;
    font-size: 16px;
    line-height: 23px;
    padding: 16px;

    &:not(:last-child) {
      margin-bottom: $spacing * 6;
    }

    @include themify($themes) {
      background: themed('modalPrimary');
      border: 1px solid themed('modalStrokePrimary');
      color: themed('textSecondary');
    }

    .popup-box & {
      margin-bottom: $spacing * 6;
    }

    div {
      display: inline;
    }
  }

  &__link {
    font-size: 16px;

    @extend %link;

    &.feedback-button {
      display: inline-block;
    }
  }
}

/* Обертка */

.tooltip-popup-content {
  padding: 24px;
  max-width: 375px;
  min-height: 370px;
  @extend %popup-basics;

  &.popup-content {
    width: 343px;
    max-width: calc(100% - $spacing * 4);
    padding: 16px;
  }
}

.tooltip-popup-arrow {
  color: $grey-snow;

  .dark-theme & {
    color: $dark-mode-block;
  }
}
