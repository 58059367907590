/**
 * # "Back to top" button
 */
/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Variables
 */
$button-size: 38px;

/**
 * This container keeps the backToTop button
 * within its boundaries inside the `.main`
 * element. Even when the user scrolled to
 * the end of the page.
 */
.float-back-to-top {
  bottom: 0;
  position: sticky;
  z-index: 9;
}

.back-to-top {
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  bottom: $spacing * 4;
  box-shadow: 0 2px 5px rgba($black, 0.2);
  display: flex;
  height: $button-size;
  justify-content: center;
  right: $spacing * 2;
  opacity: 0;
  position: absolute;
  transition:
    0.25s background-color ease-out,
    0.25s opacity ease-out;
  width: $button-size;
  z-index: $z-index-header-default;

  @include themify($themes) {
    background-color: themed('backgroundPrimary');
    border-color: themed('strokePrimary');
  }

  @include minSm {
    right: auto;
    left: $spacing * 10;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    @include themify($themes) {
      background-color: themed('backgroundPrimaryPressed');
    }
  }

  &::after {
    content: '';
    display: block;
    height: $spacing * 5;
    rotate: -90deg;
    transition: 0.25s background-color ease-out;
    width: $spacing * 5;

    -webkit-mask:  url("/common/images/icons/chevron.svg") no-repeat center center;
    mask: url("/common/images/icons/chevron.svg") no-repeat center center;

    @include themify($themes) {
      background-color: themed('iconSecondary');
    }
  }

  &:focus-visible {
    @include themify($themes) {
      outline: 2px solid themed('strokeFocus');
    }
  }

  &--visible {
    opacity: 1;
  }
}
