/**
 * # Productspopover
 */

/**
 * ## Imports
 */
 @import 'src/common/styles/variables.scss';
 @import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.popover {
    border-style: solid;
    border-width: 1px;
    border-radius: $border-radius-xl;
    box-shadow: 0 5px 10px rgba($black, .15);
    opacity: 0;
    position: absolute;
    right: 0;
    top: calc(100%);
    transition: all .25s ease-in;
    visibility: hidden;
    width: 320px;

    @include themify($themes) {
        background-color: themed('backgroundTertiary');
        border-color: themed('strokeQuartary');
    }

    &.popover--visible {
        opacity: 1;
        top: calc(100% + ($spacing * 2));
        visibility: visible;
    }
}

.products-list {
    padding: 0 ($spacing * 5) 2px;

    &__item {
        height: 70px;

        &:not(:last-of-type) {

            @include themify($themes) {
                border-bottom: 1px solid themed('strokeQuartary');
            }
        }
    }

    &__link {
        background-position-x: left;
        display: block;
        font-size: 16px;
        height: 100%;
        padding: 23px 0 $spacing ($spacing * 11);
        position: relative;
        text-decoration: none;
        width: 100%;
        @extend %arrow-right-pseudo-element;

        &::after {
            .light-theme .products-list__item & {
                background-image: url("/img/homepage_rebrand/icons/arrow-right-dark.svg");
            }
        }

        @include themify($themes) {
            color: themed('textPrimary');
        }

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }

        &--browser {
            @extend %tempest-product-icon-browser;
        }

        &--infinity-browsers {
            @extend %tempest-product-icon-infinity-browsers;
            background-size: 13%;
            background-position: left;
        }

        &--search {
            @extend %tempest-product-icon-search;
        }
    }
}