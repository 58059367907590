@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.movie-slider {
  &.swiper-component {
    .swiper-arrow {
      top: 50px;

      &--prev {
        left: -28px;
      }

      &--next {
        right: -28px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .swiper-slide {
    width: 83px;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;

    @include minSm {
      width: 96px;
    }

    @include minMd {
      width: 83px;
    }
  }

  .swiper-wrapper{
    transform-style: preserve-3d;
  }
}
