@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.instant-answer-widget {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include maxSm {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  &__input-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    &:not(:last-child) {
      @include maxSm {
        margin-bottom: $spacing * 2;
      }
    }
  }

  &__input {
    width: 100%;
    height: 74px;
    font-size: 28px;
    line-height: 34px;
    padding: 16px;
    border-radius: $border-radius-m;
    border: none;
    background: none;

    &:hover,
    &:focus {
      outline: none;
    }

    @include themify($themes) {
      color: themed('textPrimary');
    }

    @media (max-width: 768px) {
      font-size: 20px;
      height: 55px;
    }

    &--with-title {
      padding-top: 42px;
      height: 84px;

      @media (max-width: 768px) {
        height: 73px;
      }
    }
  }

  &__input-title {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 12px;
    text-transform: uppercase;

    @include themify($themes) {
      color: themed('textTertiary');
    }
  }

  &__arrow {
    margin: 7px;

    svg {
      @include themify($themes) {
        stroke: themed('accentPrimary');
      }
    }

    @include maxSm {
      display: none;
    }
  }

  &__title {
    display: block;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 7px;
    text-transform: uppercase;

    @include themify($themes) {
      color: themed('textTertiary');
    }
  }

  &__text {
    font-size: 28px;
    line-height: 34px;
    margin: 0;

    &::first-letter {
      text-transform: capitalize;
    }

    @include themify($themes) {
      color: themed('textPrimary');
    }

    @include maxSm {
      font-size: 24px;
      line-height: 31px;
    }
  }

  &.widget-paper--animated-border::before {
    height: 800%;
    width: 120%;
    top: -350%;
    left: -10%;
    animation-duration: 7s;

    /*TODO: need to be rewritten with the more suitable solution*/
  }
}
