@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.category-tooltip {
  &__trigger {
    font-size: 14px;
    cursor: pointer;

    @include themify($themes) {
      color: themed('textTertiary');
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

#popup-root {
  .category-tooltip-content {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    border-radius: 10px;
    padding: 5px 10px 3px;
    top: 20px;
    max-width: 134px;

    @include themify($themes) {
      background-color: themed('backgroundSecondaryHover');
      border-color: themed('backgroundSecondaryHover');
      color: themed('textTertiary');
    }

    div {/* TODO: find the better way to assign styles */
      height: 4px !important;
      width: 8px !important;
    }
  }

  .category-tooltip-arrow {
    @include themify($themes) {
      color: themed('backgroundSecondaryHover');
    }
    filter: none;
    stroke: none;
  }
}