@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.skeleton-item {
  height: 14px;

  @include themify($themes) {
    background-color: themed('backgroundSecondaryHover');
  }

  &--line {
    border-radius: $border-radius-s;
    width: 100%;
    height: 14px;
  }

  &--circle {
    height: 24px;
    width: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
  }

  &--gradient-field {
    height: 225px;
    width: 100%;
    border-radius: $border-radius-m;
    background-image: linear-gradient(90deg,
      rgba(242, 245, 250, 0) 0%,
      rgba(242, 245, 250, 0) 45%,
      #F2F5FA 49%,
      #F2F5FA 51%,
      rgba(242, 245, 250, 0) 55%,
      rgba(242, 245, 250, 0) 100%);
    background-size: 300% 300%;
    animation: 5s linear infinite skeletonLoading;

    .dark-theme & {
      background-image: linear-gradient(90deg,
        rgba(36, 41, 51, 0) 0%,
        rgba(36, 41, 51, 0) 45%,
        #242933 49%,
        #242933 51%,
        rgba(36, 41, 51, 0) 55%,
        rgba(36, 41, 51, 0) 100%);
    }

    @include themify($themes) {
      background-color: themed('backgroundSecondary');
    }
  }

  &--dark {
    @include themify($themes) {
      background-color: themed('backgroundPrimaryHover');
    }
  }
}

@keyframes skeletonLoading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.skeleton-footer {
  display: flex;
  justify-content: space-between;
  padding: 12px 0 0;

  @include maxSm {
    bottom: 8px;
    padding: 12px 16px 16px;
    position: absolute;
    width: 100%;
  }

  &__item {
    width: 70px;
    height: 16px;

    @include maxSm {
      @include themify($themes) {
        background-color: themed('backgroundPrimaryHover');
      }
    }

    &--2 {
      @include minSm {
        width: 52px;
      }
    }
  }
}

