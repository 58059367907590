@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.single-business-skeleton {
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing * 5;

    @include minSm {
      flex-direction: column;
    }
  }

  &__top-item-1 {
    width: 163px;
    height: 24px;
    margin-bottom: $spacing * 2;

    @include minSm {
      width: 259px;
    }
  }

  &__top-item-2 {
    width: 143px;
    height: 24px;
    margin-bottom: $spacing * 2;

    @include minSm {
      height: 27px;
    }
  }

  &__top-item-3 {
    width: 73px;
    height: 27px;
    margin-bottom: $spacing * 2;

    @include minSm {
      width: 143px;
      height: 16px;
      margin-bottom: 0;
    }
  }

  &__top-item-4,
  &__top-item-5 {
    width: 111px;
    height: 14px;
    margin-bottom: $spacing;
  }

  &__top-item-6 {
    width: 65px;
    height: 16px;
  }

  &__top-part-1 {
    @include minSm {
      order: 1;
    }
  }

  &__top-part-2 {
    width: 140px;
    height: 140px;

    @include minSm {
      width: auto;
      margin-top: -20px;
      margin-right: -16px;
      margin-left: -16px;
      margin-bottom: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__block {
    padding: $spacing * 4;

    &:not(:last-child) {
      margin-bottom: $spacing * 2;
    }

    &--1 {
      display: flex;
      justify-content: space-between;
      padding-left: 0;
      padding-right: 0;
    }

    &--2 {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  &__part-1-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &:not(:last-child) {
      border-right-width: 1px;
      border-right-style: solid;

      @include themify($themes) {
        border-color: themed('strokePrimary');
      }
    }
  }

  &__item-1-1 {
    width: 24px;
    height: 24px;
    margin-bottom: $spacing * 2;
  }

  &__item-1-2 {
    width: 50px;
    height: 16px;
  }

  &__part-2-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ($spacing * 3) 0;

    &:not(:last-child) {
      border-bottom-width: 1px;
      border-bottom-style: solid;

      @include themify($themes) {
        border-color: themed('strokePrimary');
      }
    }
  }

  &__item-2-1 {
    width: 72px;
    height: 20px;
  }

  &__item-3-1 {
    width: 81px;
    height: 24px;
    margin-bottom: $spacing * 3;
  }

  &__part-3-1 {
    display: flex;
    align-items: center;
    margin-bottom: $spacing * 2;
  }

  &__circle {
    width: 27px;
    height: 27px;
    min-width: 27px;
    min-height: 27px;
    margin-right: $spacing * 2;
  }

  &__item-3-2 {
    width: 116px;
    height: 16px;
  }

  &__item-3-3 {
    width: 311px;
    height: 14px;
    margin-bottom: 9px;

    @include minSm {
      width: calc(100% - 8px);
    }
  }

  &__item-3-4 {
    width: 239px;
    height: 14px;
    margin-bottom: $spacing * 2;
  }

  &__item-3-5 {
    width: 85px;
    height: 16px;
  }
}