@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.movie-infocard {
  position: relative;

  &.widget-paper {
    padding-top: 0;

    @include maxSm {
      padding-top: ($spacing * 4);
    }
  }

  &__block {
    padding: $spacing * 4;

    &:not(:last-child) {
      margin-bottom: $spacing * 2;
    }
  }

  &__media {
    display: flex;
    margin-bottom: ($spacing * 4);
  }

  &__poster {
    height: 144px;
    width: 97px;
    max-height: 144px;
    max-width: 97px;
    min-height: 144px;
    min-width: 97px;
    overflow: hidden;
    margin-right: ($spacing * 2);
    border-radius: $border-radius-m;
  }

  &__poster-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__video-img-wrapper {
    display: block;
    margin: 1px ($spacing * -4 + 1) 19px;
    border-radius: $border-radius-xl $border-radius-xl 0 0;
    height: 237px;
    position: relative;
    overflow: hidden;

    .movie-infocard__media & {
      margin: 0;
      border-radius: $border-radius-m;
      height: 144px;
      width: 238px;
    }

    .movie-infocard {
      &__poster-wrapper {
        background-size: cover;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .movie-infocard__poster-black-layer {
          position: absolute;
          background-color: rgba(0,0,0,0.8);
          min-width: 100%;
          min-height: 100%;
        }
      }
    }

    &:hover {
      .movie-infocard {
        &__video-info-wrapper {
          background-color: rgba(255, 255, 255, 0.8);
          background-image: url("/img/play-dark.svg");

          .movie-infocard {
            &__video-info-text {
              display: none;

              @media (max-width: 768px) {
                display: inline-block;
                color: rgba(64, 64, 64, 1);
              }

              &.hover {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

  &__video-img {
    max-height: 86.5%;
    border-radius: $border-radius-m;
    z-index: $z-index-default;
  }

  &__video-img-mobile {
    max-height: 100%;
  }

  &__video-info-wrapper {
    position: absolute;
    display: block;
    bottom: 8px;
    left: 8px;
    padding: 3px 8px 3px 25px;
    border-radius: 25px;
    background: rgba(64, 64, 64, 0.8) url("/img/play.svg") no-repeat 10px center;
    background-size: 8px;
    line-height: 16px;
    z-index: $z-index-default;
    transition: 0.25s background-color ease-out;
  }

  &__video-info-text {
    color: $white;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;

    &.hover {
      display: none;
      color: rgba(64, 64, 64, 1);
    }
  }

  &__dot {
    font-size: 7px;
    vertical-align: top;
    margin: 0 3px;

    .movie-infocard__subtitle & {
      font-size: 6px;
    }
  }

  &__title {
    display: inline-block;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    margin: 0 0 12px;

    &--top {
      font-size: 30px;
      line-height: 34px;
      margin-bottom: ($spacing * 2);
    }

    @media (max-width: 768px) {
      margin-bottom: 7px;
    }

    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  &__title-text {
    margin-right: 17px;

    &--link {
      @include themify($themes) {
        color: themed('textPrimary');
      }

      &:hover {
        text-decoration: underline;

        @include themify($themes) {
          color: themed('link');
        }
      }
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
    margin: 0 0 ($spacing * 2);

    @include themify($themes) {
      color: themed('textTertiary');
    }

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: $spacing * 4;
    }
  }

  &__rating-short {
    display: inline-flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1;

    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  &__rating-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: $tmdb-green;
    border-radius: 50%;
    margin-top: 0;
    margin-right: 3px;
  }

  &__rating-text {
    display: inline-block;
    padding-top: 0;
    margin-top: 0;
    line-height: 1.4;
  }

  &__rating-label {
    display: inline-block;
    padding-top: -2px;
  }

  .abbreviation {
    margin-top: 0;
    line-height: 1;
  }

  &__text {
    font-size: 14px;
    line-height: 21px;
    margin: 0;

    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  .line {
    display: block;
    justify-content: space-between;
    align-items: flex-start;
    padding: $spacing 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &__caption {
      display: inline;
      font-size: 14px;
      line-height: 20px;
      font-style: normal;
      font-weight: 700;

      @include themify($themes) {
        color: themed('textPrimary');
      }
    }

    &__value {
      display: inline;
      word-break: break-word;
      text-align: right;
    }

    &__value-item {
      display: inline;
      font-size: 14px;

      @include themify($themes) {
        color: themed('textPrimary');
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &:last-child {
        .line__comma {
          display: none;
        }
      }

      &--link {
        cursor: pointer;

        &:hover {
          text-decoration: underline;

          @include themify($themes) {
            color: themed('link');
          }
        }

        @extend %link;
      }
    }
  }

  .movie-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      margin-bottom: 10px;
    }

    &__img-wrapper {
      width: 36px;
      height: 36px;
      overflow: hidden;
      margin-right: ($spacing * 4);
      border-radius: 6px;
      padding-top: 3px;
    }

    &__img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    &__info {
      margin-right: auto;
    }

    &__caption {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 1px;

      @include themify($themes) {
        color: themed('textPrimary');
      }
    }

    &__text {
      font-size: 12px;
      line-height: 14px;

      @include themify($themes) {
        color: themed('textTertiary');
      }
    }

    &__value {
      font-size: 20px;
      line-height: 20px;

      @include themify($themes) {
        color: themed('textPrimary');
      }
    }
  }

  &__cast-slider {
    .swiper-container {
      @media (max-width: 768px) {
        margin-left: ($spacing * -4);
        margin-right: ($spacing * -4);
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  &__reviews-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__reviews-button {
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
    margin-bottom: ($spacing * 2);

    &:hover {
      text-decoration: none;
    }

    @extend %link;
  }

  .movie-review {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__img-wrapper {
      width: 37px;
      min-width: 37px;
      height: 37px;
      overflow: hidden;
      margin-right: 12px;
      border-radius: 50%;
    }

    &__img {
      min-width: 100%;
      width: 100%;
      height: 100%;
      min-height: 100%;
      object-fit: cover;
    }

    &__info {
      flex: 1 1 auto;
    }

    &__top {
      display: flex;
      margin-bottom: 3px;
    }

    &__name {
      font-size: 14px;
      font-weight: normal;
      line-height: 21px;
      margin: 0;

      @include themify($themes) {
        color: themed('textPrimary');
      }
    }

    &__evaluation {
      margin-left: ($spacing * 2);

      svg {
        margin-bottom: -2px;
      }

      span {
        font-size: 14px;
        line-height: 21px;
        vertical-align: inherit;
        margin-left: 6px;

        @include themify($themes) {
          color: themed('textPrimary');
        }
      }
    }

    &__text {
      font-size: 14px;
      line-height: 20px;
      margin: 0;

      @include themify($themes) {
        color: themed('textTertiary');
      }
    }
  }

  &__profiles-list {
    display: flex;
  }

  &__watch-now-link {
    text-decoration: none;

    a:hover {
      text-decoration: underline;
    }

    @extend %link;
  }
}
