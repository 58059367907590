/**
 * # Footer styles
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Variables
 */
$footer-feedback-button-break-point: 1101px;

.footer {
  border-top: 1px solid $lavender;
  flex: 0 0 auto;
  position: relative;
  z-index: $z-index-footer-default;

  &--fixed {
    @media (max-width: 654px) {
      position: fixed;
      bottom: 0;
      width: 100vh;
    }
  }

  @include themify($themes) {
    background-color: themed('backgroundSecondary');
  }

  .dark-theme & {
    border-top: 1px solid $light-transparent;
  }

  &__inner {
    max-width: 1440px;
    padding: 23px 20px 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    @include minSm {
      max-width: 712px;
      padding: ($spacing * 6) ($spacing * 4);
    }

    @include minMd {
      max-width: 1276px;
      padding: ($spacing * 6) 38px;
    }

    @media (min-width: 800px) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .footer__tempest {
      color: $blue;
      display: none;
      line-height: 20px;

      @media (min-width: 800px) {
        display: block;
      }
    }
  }

  &__menu {
    display: flex;
    flex-wrap: nowrap;
    gap: $spacing * 8;

    @include minSm {
      gap: $spacing * 6;
    }

    @media (min-width: 800px) {
      max-width: 100%;
      text-align: left;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }

  &__logo {
    display: block;
    height: 25px;
    min-height: 25px;
    width: 25px;
    min-width: 25px;
  }

  &__menu-body {
    display: flex;
    flex-wrap: wrap;
    column-gap: $spacing * 8;
    row-gap: $spacing * 3;

    @include minMd {
      gap: $spacing * 6;
      flex-wrap: nowrap;
    }
  }

  &__menu-items {
    display: flex;
    flex-direction: column;

    @include minSm {
      flex-direction: row;
      align-items: center;
    }
  }

  &__menu-item {
    display: inline-block;
    line-height: 20px;
    white-space: nowrap;

    &:not(:last-child) {
      padding-right: 16px;
      padding-bottom: 11px;
    }

    &--button {
      font-size: 14px;
      border-bottom: 1px solid transparent;

      @include themify($themes) {
        color: themed('textSecondary');
      }

      &:hover {
        text-decoration: underline;
      }
    }

    @include minSm {
      &:not(:last-child) {
        padding-right: 24px;
        padding-bottom: 0;
      }
    }

    &.link {
      font-size: 14px;
    }
  }

  &__copyright {
    .dark-theme & {
      color: $grey;
    }
  }

  &__tempest {
    .dark-theme & {
      color: $blue-light;
    }
  }

  &__link {
    .dark-theme & {
      color: rgba(255,255,255,0.7);
    }
  }
}

.float-feedback {
  position: sticky;
  bottom: 0;
  text-align: right;
  /* - This z-index guarantees the clickability
   * of the feedback button. */
  z-index: $z-index-footer-default;
  max-width: 100%;

  @media (max-width: 1558px) {
    /* - Necessary `relative` positioning to have a child <button>
     * element being positioned absolutely. */
    position: relative;
    text-align: left;

    @include themify($themes) {
      background-color: themed('backgroundSecondary');
    }
  }

  @media (max-width: $footer-feedback-button-break-point) {
    border-top: 1px solid $lavender;
    margin: 0 auto;
    max-width: 680px;
    padding: 35px 0 40px;

    .dark-theme & {
      background: $dark;
      border-color: $asphalt-grey;
    }
  }

  @include maxSm {
    padding: ($spacing * 4) ($spacing * 5);
    border-top: none;
    max-width: 680px;
  }

  &__title {
    display: none;

    @media (max-width: $footer-feedback-button-break-point) {
      display: block;
      font-size: 18px;
      line-height: 19px;
      margin-bottom: 7px;

      @include themify($themes) {
        color: themed('textPrimary');
      }
    }
  }

  &__text {
    display: none;

    @media (max-width: $footer-feedback-button-break-point) {
      display: block;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 17px;

      @include themify($themes) {
        color: themed('textTertiary');
      }
    }
  }

  &__button {
    position: absolute;
    right: $spacing * 8;
    bottom: $spacing * 10;
    background: $grey-snow;
    border-radius: 8px;
    font-size: 14px;
    color: $secondary-color;
    padding: 8px 19px;
    z-index: 10;
    cursor: pointer;

    @include themify($themes) {
      border: 1px solid themed('strokePrimary');
      background: themed('backgroundPrimary');
    }

    &:hover {
      text-decoration: none;
    }

    .dark-theme & {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
      color: $primary-color-dark-theme;
    }

    @media (max-width: 1508px) {
      right: $spacing * 8;
    }

    @media (max-width: $footer-feedback-button-break-point) {
      position: static;
      color: $text;

      .dark-theme & {
        box-shadow: none;
      }
    }

    .feedback-button {
      &__text {
        padding-bottom: 1px;
      }
    }
  }
}
