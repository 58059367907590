@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.weather-widget-skeleton {
  &__top {
    @include minSm {
      display: flex;
      justify-content: space-between;
    }
  }

  &__group {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: -$spacing;
    margin-bottom: $spacing * 5;

    @include minSm {
      align-items: flex-start;
      margin-top: 0;
    }
  }

  &__square {
    width: 57px;
    height: 57px;
    min-width: 57px;
    min-height: 57px;
    margin-right: $spacing * 4;

    @include minSm {
      width: 78px;
      height: 78px;
      min-width: 78px;
      min-height: 78px;
      margin-right: $spacing * 6;
    }
  }

  &__block-1 {
    @include minSm {
      order: 1;
      text-align: right;

      .skeleton-item {
        margin-left: auto;
      }
    }
  }

  &__item-1-1 {
    width: 134px;
    height: 20px;
    margin-bottom: $spacing;
  }

  &__item-1-2 {
    width: 72px;
    height: 14px;
    margin-bottom: $spacing;

    @include minSm {
      width: 57px;
    }
  }

  &__block-2 {
    margin-right: auto;

    @include minSm {
      align-self: center;
      margin-right: 52px;
    }
  }

  &__item-2-1 {
    width: 41px;
    height: 36px;
    margin-bottom: 2px;
  }

  &__item-2-2 {
    width: 114px;
    height: 18px;
    margin-bottom: 2px;

    @include minSm {
      width: 74px;
      height: 21px;
    }
  }

  &__item-3 {
    width: 103px;
    height: 12px;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    @include minSm {
      width: 80px;
    }
  }

  &__field {
    height: 178px;
    margin-bottom: $spacing * 2;
    border-width: 1px;
    border-style: solid;

    @include themify($themes) {
      border-color: themed('strokePrimary');
    }
  }

  &__cards {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    padding: $spacing * 4;

    @include minSm {
      padding-left: 30px;
    }
  }

  &__card {
    width: 45px;
    min-width: 45px;
    height: 81px;
    margin-right: $spacing * 8;
  }
}
