@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.wiki-how {
  &__header {
    margin-bottom: $spacing * 5;
  }

  &__list {
    display: flex;
    justify-content: center;
    gap: 7px;

    @media (max-width: 678px) {
      justify-content: flex-start;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      transition: 0.3s all ease-in;
      margin-left: -($spacing * 4);
      margin-right: -($spacing * 4);
      padding-left: ($spacing * 4);
      padding-right: ($spacing * 4);

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__card {
    max-width: 159px;
    height: 100%;
  }
}