/**
* # Utilities.
* Common classes, placeholders and mixins for reusage to avoid code repetition.
*/

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';

/**
 * ## Sass placeholders
 */
%no-underline {
  text-decoration-line: none;
}

/**
 * Use sr-only to hide an element visually without hiding it from
 * screen readers.
 */
%sr-only:not(:focus):not(:active) {
  border-width: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

%not-sr-only {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  padding: 0;
  position: static;
  white-space: normal;
  width: auto;
}

%no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

/**
 * %ellipsis is a placeholder that allows for truncating
 * words, which should not run across multiple line and
 * display an ellipsis (...) at the end.
 */
%ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/**
 * @mixin center-x
 * @mixin center-y
 * @mixin center-xy
 */
@mixin center-x($position: relative) {
  position: $position;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-y($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-xy($position: relative) {
  position: $position;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/**
 * %button-as-link resets a `<button>` element's
 * look and feel to look like a simple link.
 */
%button-as-link {
  background-color: transparent;
  border: none;
  font-size: 1em;
  line-height: 1;
  padding: 0;

  @include themify($themes) {
    color: themed('link');
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

/**
 * %link is properties for the common link's colors
 */

%link {
  @include themify($themes) {
    color: themed('link');
  }

  &:visited {
    @include themify($themes) {
      color: themed('linkVisited');
    }
  }
}

/**
 * %button is properties for the common buttons colors
 */

%button {
  @include themify($themes) {
    background-color: themed('backgroundPrimary');
    border: 1px solid themed('strokePrimary');
  }

  &:hover,
  &:focus {
    @include themify($themes) {
      background-color: themed('backgroundPrimaryHover');
    }
  }

  &:active {
    @include themify($themes) {
      background-color: themed('backgroundPrimaryPressed');
    }
  }
}

/**
 * %image-placeholder is a container holding images in
 * news item teasers.
 */
 %image-placeholder {
  border-radius: 0 $border-radius-m $border-radius-m 0;
  display: inline-block;
  flex-shrink: 0;
  object-fit: cover;
  overflow: hidden;
  border-radius: $border-radius-s;
  height: 102px;
  margin: $spacing * 4;
  width: 102px;

  @media (min-width: $news-results-layout-break-point) {
    border-radius: 0 $border-radius-m $border-radius-m 0;
    height: 184px;
    margin: 0 0 0 ($spacing * 6);
    width: 184px;
  }
}

/**
 * ✔ Icon
 */
%checkmark-element {
  content: '';
  position: absolute;
  right: $spacing * 2;
  top: $spacing * 2;
  width: $spacing * 2;
  height: 14px;
  background: url("/img/check.svg") no-repeat center;

  .dark-theme & {
    background: url("/img/check-dark.svg") no-repeat center;
  }
}

/**
 * ### Popup Basics
 */
%popup-basics {
  box-shadow: 0 2px 15px rgba($black, 0.4);
  border-radius: $border-radius-xl;

  @include themify($themes) {
    background-color: themed('backgroundTertiary');
    border: 1px solid themed('modalStrokePrimary');
  }
}

/**
 * ## Sass mixins
 */

/**
 * Truncate text strings.
 * @param {number} $lineCount.
 */
@mixin truncate($lineCount: 2) {
  /**
  * These styles truncate the text string on
  * a specified line.
  */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lineCount;
  -webkit-box-orient: vertical;
  /**
  * Necessary to keep the line-clamp working
  * inside a flex layout as well.
  */
  flex-grow: 0;
}

@mixin gradient-border(
    $border: 1px,
    $gradient: $gradient-4
) {
    position: relative;
    background-clip: padding-box;
    border: $border solid transparent;

    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      background: $gradient;
    }
}

@mixin animated-border(
  $border-radius: $border-radius-xl,
  $background-image: repeating-conic-gradient(transparent 50%, #D28BFF 55%, rgba(210, 139, 255, 0) 60%, transparent 100%)
) {

  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: $background-image;

    animation: rotate 10s linear infinite;

    @include themify($themes) {
      background-color: themed('strokePrimary');
    }

    .dark-theme & {
      background-image: repeating-conic-gradient(transparent 50%, #E6C0FF 55%, #85629C 58%, rgba(133, 98, 156, 0) 60%, transparent 100%);
    }
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: $border-radius - 1;

    @include themify($themes) {
      background: themed('backgroundPrimary');
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
}

/**
 * ## Utility classes
 */
.no-underline {
  @extend %no-underline;
}

.figure {
  margin: 0;
}

.abbreviation {
  text-decoration-line: none;
}

.sr-only {
  @extend %sr-only;
}

.not-sr-only {
  @extend %not-sr-only
}

/*
 * ## Media queries / breakpoints
 */
@mixin maxXsm { /* 575px */
  @media (max-width: #{$screen-max-xsm}) {
    @content;
  }
}

@mixin minXsm { /* 576px */
  @media (min-width: #{$screen-min-xsm}) {
    @content;
  }
}

@mixin maxSm { /* 759px */
  @media (max-width: #{$screen-max-sm}) {
    @content;
  }
}

@mixin minSm { /* 760px */
  @media (min-width: #{$screen-min-sm}) {
    @content;
  }
}

@mixin maxSmMd { /* 1023px */
  @media (max-width: #{$screen-max-sm-md}) {
    @content;
  }
}

@mixin minSmMd { /* 1024px */
  @media (min-width: #{$screen-min-sm-md}) {
    @content;
  }
}

@mixin maxMd { /* 1161px */
  @media (max-width: #{$screen-max-md}) {
    @content;
  }
}

@mixin minMd { /* 1162px */
  @media (min-width: #{$screen-min-md}) {
    @content;
  }
}

@mixin maxMdAside { /* 1220px */
  @media (max-width: #{$screen-max-md-aside}) {
    @content;
  }
}

@mixin minMdAside { /* 1221px */
  @media (min-width: #{$screen-min-md-aside}) {
    @content;
  }
}

@mixin maxLg { /* 1439px */
  @media (max-width: #{$screen-max-lg}) {
    @content;
  }
}

@mixin minLg { /* 1440px */
  @media (min-width: #{$screen-min-lg}) {
    @content;
  }
}

/**
 * ## Overlap to the bottom neighboring container.
 */
@mixin bottom-overlap($height: 100px, $background-color: $marketing-blue-500) {
  &::after {
      background-color: $background-color;
      content: "";
      display: block;
      height: $height;
      width: 100%;
  }
}

/**
 * ## Standard paragraphs
 */
 %standard-typographic-format {
  color: $silver-500;
  font-size: 16px;
  line-height: 2;
}

/**
 * ## Loading Animation on Container
 */
@mixin loading-animation-on-container(
  $gradient-color-01: rgba(14, 16, 20, 0) 0%,
  $gradient-color-02: #0E1014 50%,
  $gradient-color-03: rgba(14, 16, 20, 0) 100%,
) {
  &::before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background: linear-gradient(
      90deg,
      $gradient-color-01,
      $gradient-color-02,
      $gradient-color-03);
    background-size: 100%;
    animation: 3000ms linear infinite loading;
  }
}

@keyframes loading {
  0% {
    left: -206px;
  }

  100% {
    left: 100%;
  }
}

/**
 * ## Button CTA Rebranded Version
 * Default class for this button is `.button-cta-rebrand`.
 */
@mixin interaction-arrow(
  $background-color: rgba($black, .3),
  $background-image-1: "/img/homepage_rebrand/icons/arrow-right.svg",
  $background-image-2: "/img/homepage_rebrand/icons/arrow-right.svg",
  $background-position: (8px center, -16px center),
  $background-size: 14px 14px,
  $dimension: 30px,
  $background-position-hover: (32px center, 8px center),
) {
    &::after {
      background-color: $background-color;
      background-image:
        url($background-image-1),
        url($background-image-2);
      background-position: $background-position;
      background-size: $background-size;
      background-repeat: no-repeat;
      border-radius: 100%;
      content: "";
      display: block;
      height: $dimension;
      transition: background-position .2s ease-in-out;
      width: $dimension;
      animation-delay: 800ms;
    }

    &:hover::after {
      background-position: $background-position-hover;
    }
}

@mixin button-cta {
  // border: 1px solid $white;
  /* - Doesn't work with rounded corners.
   * border-image: linear-gradient(60deg, #E13E8DFF, #ef4e7b, #a166ab, #5341C5FF, #3D2F99FF);
   */
  border-image-slice: 1;
  border-radius: $spacing * 6;
  border: double 1px transparent;
  /* - Todo: align colors with designers and new color system. */
  // background-image: linear-gradient(51.14deg, #6081F5 -11.73%, #5341C5 57.09%, #3D2F99 102.08%),
  //   linear-gradient(60deg, #E13E8DFF, #ef4e7b, #a166ab, #5341C5FF, #3D2F99FF);
    background-image: linear-gradient(53.67deg, #7E64DC 12.55%, #A159EB 41.14%, #E959A6 68.32%, #F55196 87.77%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  color: $white;
  font-size: 12px;
  font-family: 'Poppins Semi Bold', sans-serif;
  height: 47px;
  line-height: 1;
  margin-bottom: $spacing * 3;
  padding: 0;
  position: relative;
  transition: all .25s ease-in;
  /* Please don't set a `z-index` on this element, or its
   * pseudo element can't display the optional glow behind it.
   */

  &:hover {
    cursor: pointer;
    // background-image: linear-gradient(12.19deg, #6081F5 -1.49%, #5341C5 100.23%, #3D2F99 166.75%),
    //   linear-gradient(60deg, #E13E8DFF, #ef4e7b, #a166ab, #5341C5FF, #3D2F99FF);
    background-image: linear-gradient(53.67deg, #7E64DC 12.55%, #A159EB 41.14%, #E959A6 68.32%, #F55196 87.77%);
  }

  .button-cta__body {
    display: block;
    padding: ($spacing * 2) ($spacing * 4);
    white-space: nowrap;
  }

  /**
   * ### Button with Arrow Indicator
   */
  &.button-cta-rebrand--arrow {
    @include interaction-arrow;

    &::after {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    /**
     * SASS Mixins don't seem to operate correctly when the
     * `&` operator is used to reference to parent elements.
     * Therefor I unfortunately needed to create multiple
     * class instances of `.button-cta__body`.
     * Todo: evaluate if better notations are available.
     */
    .button-cta__body {
      padding-right: 70px;
    }
  }

  &.button-cta-rebrand--animated-arrow {
    &::after {
      animation: pulse 1.5s infinite ease-out;
    }

    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 rgba($black, .3);
      }
      20% {
        box-shadow: 0 0 0 5px rgba($black, .3);
       }
      30% {
        box-shadow: 0 0 0 0 rgba($black, .3);
      }
      100% {
        box-shadow: 0 0 0 0 rgba($black, .3);
      }
    }

    @-webkit-keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 rgba($black, .3);
      }
      20% {
        box-shadow: 0 0 0 5px rgba($black, .3);
       }
      30% {
        box-shadow: 0 0 0 0 rgba($black, .3);
      }
      100% {
        box-shadow: 0 0 0 0 rgba($black, .3);
      }
    }
  }

  &.button-cta-rebrand--arrow-large {
    @include interaction-arrow(
      $background-color: rgba($black, .2),
      $background-position: (13px center, -30px center),
      $background-size: 23.42px 23.42px,
      $dimension: 48.79px,
      $background-position-hover: (60px center, 13px center),
    );

    &::after {
      animation: largePulse 1.5s infinite ease-out;
    }

    @keyframes largePulse {
      0% {
        box-shadow: 0 0 0 0 rgba($black, 0.2);
      }
      20% {
        box-shadow: 0 0 0 9px rgba($black, 0.2);
       }
      30% {
        box-shadow: 0 0 0 0 rgba($black, 0.2);
      }
      100% {
        box-shadow: 0 0 0 0 rgba($black, 0.2);
      }
    }

    @-webkit-keyframes largePulse {
      0% {
        box-shadow: 0 0 0 0 rgba($black, 0.2);
      }
      20% {
        box-shadow: 0 0 0 9px rgba($black, 0.2);
       }
      30% {
        box-shadow: 0 0 0 0 rgba($black, 0.2);
      }
      100% {
        box-shadow: 0 0 0 0 rgba($black, 0.2);
      }
    }
  }

  /**
   * ### Button with Big Layout
   */
  &--big {
    border: 0 solid transparent;
    border-radius: $spacing * 10;
    font-size: 16px;
    height: $spacing * 20;
    margin: ($spacing * 3) 0 ($spacing * 10);

    .button-cta__body {
      padding: ($spacing * 2) ($spacing * 8);
    }

    /**
     * ### Button with Big Layout and Arrow Indicator
     */
    &.button-cta-rebrand--arrow {
      &::after {
        background-size: 24px 24px;
        background-position: 14px center,
                           -24px center;
        height: 50px;
        right: $spacing * 4;
        width: 50px;
      }

      &:hover::after {
        background-position: 62px center,
                             14px center;
      }

      .button-cta__body {
        align-items: center;
        border-radius: $spacing * 10;
        display: flex;
        height: 100%;
        padding-right: 82px;
        width: 100%;
      }
    }
  }

  /**
  * ### Button with Lofty Layout
  */
  &--lofty {
    background-color: $white;
    background-image: none;
    color: rgba($marketing-gray-900, .6);
    font-family: 'Poppins', Helvetica, Arial, sans-serif;

    &:hover {
      background-color: $platinum-200;
      background-image: none;
    }

    &:active {
      background-color: $marketing-blue-gray-200;
    }

    &.button-cta-rebrand--arrow::after {
      background-image:
        url("/img/homepage_rebrand/icons/arrow-right-dark.svg"),
        url("/img/homepage_rebrand/icons/arrow-right-dark.svg");
      background-color: $marketing-blue-20;
    }
  }

  /**
  * ### Button large
  */
  &--large {
    height: 78px;
    min-width: 227px;
    font-weight: 600;
    line-height: 42px;
    font-size: 19.8462px;
    border-radius: 66.1538px;
  }

  /**
  * ### Button with full width
  */
  &--full-width {
    width: 100%;
  }

  /**
   * ### Button with Reduced Layout
   */
  &--reduced {
    background-color: $marketing-blue-450;
    background-image: none;
    font-family: 'Poppins', sans-serif;

    &:hover {
      background-color: $marketing-blue-150;
      background-image: none;
    }

    &:active {
      background-color: $marketing-blue-110;
    }
  }

  /**
   * ### Button with Dry Layout
   */
  &--dry {
    /* - Todo: align colors with designers and new color system. */
    background: $marketing-blue-gray-900;
    color: rgba($white, .6);
    font-family: 'Poppins', sans-serif;

    &:hover {
      background: $marketing-blue-gray-800;
    }

    &:active {
      background: $marketing-blue-gray-700;
    }
  }

  /**
   * ### Button with Glow on the Bottom
   */
  &--glow-bottom::before {
    bottom: 0;
    /* - Todo: align colors with designers and new color system. */
    box-shadow: 0 0 67px 18px rgb(0 71 255 / 50%);
    content: "";
    height: 0px;
    left: 0;
    position: absolute;
    transform: skew(-20deg, 0deg);
    width: 100%;
    z-index: -1;
  }
}

/**
 * ## Homepage Typography
 */
%homepage-teaser-subheadline {
  color: $white;
  font-size: 36px;
  font-weight: normal;
  font-family: "Poppins Semi Bold";
  line-height: 47px;
  margin: ($spacing * 2) 0;

  @media (min-width: 1024px) {
    font-size: 44px;
    line-height: 66px;
  }

  &.homepage-headline-big--alt-color {
    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  &.homepage-headline-big--centered {
    text-align: center;
  }

  /**
   * Everything else but a beauty, but designers
   * have some special wishes here... 🪄
   */
  .light-theme .home-centerwrapper-narrow--mobile-color-swap &,
  .dark-theme .home-centerwrapper-narrow--mobile-color-swap & {
    @include maxSm {
      color: $white;
    }
  }

  &.homepage-headline-big--reduced {
    font-family: "Poppins";
    @include maxSm {
      font-size: 29px;
    }
  }

  &.headline-sober,
  .headline-sober {
    font-family: "Poppins Light";
  }
}

%homepage-teaser-light-headline {
  font-family: "Poppins Light", Helvetica, Arial;
  font-size: 32px;
  line-height: 48px;

  @include minSm {
    font-size: 44px;
    line-height: 66px;
  }
}

%homepage-teaser-tiny-headline {
  color: $white;
  font-size: 12px;
  padding: 42px 0 0;
  text-transform: uppercase;

  @include minSm {
    border-bottom: 1px solid rgba($white, .1);
    padding: 42px 0 ($spacing * 6);
  }
}

%homepage-teaser-headline-allcaps {
  color: rgba($white, .5);
  font-size: 13px;
  text-transform: uppercase;
}

%headline-block-separator {
  background-color: rgba($white, .04);
  border-radius: $border-radius-s;
  color: $silver-500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: $spacing;
  padding: ($spacing * 2) $spacing 5px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

%homepage-icon-subheadline {
  background-image: url("/img/homepage_rebrand/icons/clock.svg");
  background-position: left center;
  background-repeat: no-repeat;
  font-size: 20px;
  line-height: 24px;
  margin: ($spacing * 3) 0;
  padding: 2px 0 2px 43px;
}

%homepage-teaser-paragraph {
  color: rgba($white, .7);
  line-height: 2;
  margin: 0 0 ($spacing * 2);

  /**
   * Everything else but a beauty, but designers
   * have some special wishes here... 🪄
   */
  .light-theme .home-centerwrapper-narrow--mobile-color-swap &,
  .dark-theme .home-centerwrapper-narrow--mobile-color-swap & {
    @include maxSm {
      color: $white;
    }
  }
}

%homepage-teaser-strong {
  font-family: 'Poppins Semi Bold';
}

%homepage-miniheadline {
  color: rgba($white, .6);
  font-family: "Poppins Light";
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: $spacing * 2;
}

/**
 * ## Tempest Product Icons
 */
%tempest-product-icon-browser-before-pseudo {
  content: '';
  display: block;
  height: 0;
  overflow: hidden;
  width: 0;
}

%tempest-product-icon-infinity-browsers,
%tempest-product-icon-search,
%tempest-product-icon-browser {
  background-position: left 20px;
  background-repeat: no-repeat;
}

%tempest-product-icon-infinity-browsers {
  background-image: url("/img/homepage_rebrand/icons/infinity-browsers-logo.svg");
}

%tempest-product-icon-browser {
  background-image: url("/img/homepage_rebrand/icons/tempest-swirl.svg");
}

/**
 * ## Arrow Right Pseudo Element
 */
%arrow-right-pseudo-element {
    &::after {
      background-image: url("/img/homepage_rebrand/icons/arrow-right.svg");
      content: "";
      display: block;
      height: 20px;
      top: 23px;
      transition: right .2s ease-in, opacity .2s ease-in;
      opacity: .4;
      position: absolute;
      right: 0;
      width: 20px;
  }

  &:hover::after {
    opacity: 1;
    right: $spacing * 4;
  }
}