@import 'src/common/styles/variables.scss';

.radiobutton {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;

  &__text {
    color: $secondary-color;
    font-size: 16px;
    line-height: 20px;
    margin-right: 20px;

    .dark-theme & {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &:checked + .radiobutton__button {
      @include themify($themes) {
        background-color: themed('accentPrimary');
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $white;
        width: 6px;
        min-width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }

    &:focus + .radiobutton__button,
    &:hover + .radiobutton__button {
      border-color: $purple;

      .dark-theme & {
        border-color: $button-color-dark-theme;
      }
    }

    &:disabled + .radiobutton__button {
      box-shadow: none;
      border: 1px solid $lavender;
    }

    &:disabled ~ .radiobutton__text {
      color: $lavender;
    }
  }

  &__button {
    position: relative;
    width: 22px;
    min-width: 22px;
    height: 22px;
    border: 1px solid $lavender;
    background-color: #FAFAFF;
    border-radius: 50%;

    .dark-theme & {
      border-color: rgba(255, 255, 255, 0.15);
      background: $dark-mode-block;
    }
  }
}
