@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.widget-cta {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  min-width: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: 32px;
  padding: 9px 12px;

  @include themify($themes) {
    background: themed('backgroundPrimary');
    border-color: themed('strokePrimary');
    color: themed('link');
  }

  &:hover {
    @include themify($themes) {
      background: themed('backgroundPrimaryHover');
    }
  }

  &:active {
    @include themify($themes) {
      background: themed('backgroundPrimaryPressed');
    }
  }

  &:focus-visible {
    @include themify($themes) {
      background: themed('backgroundPrimary');
      border: 1px solid themed('strokePrimary');
      outline: 1px solid themed('strokeFocus');
    }
  }

  @include maxSm {
    min-width: auto;
    width: 100%;
  }

  &--light {
    @include themify($themes) {
      background: themed('backgroundSecondary');
      stroke: themed('strokePrimary');
    }
  }

  svg {
    position: absolute;
    right: 10px;
    top: calc(50% - 1px);
    transform: translateY(-50%);

    path {
      stroke: #2D4FB2;

      .dark-theme & {
        stroke: $blue-light;
      }
    }
  }

  &.rotate-icon {
    svg {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  a {
    text-decoration: none;
    padding: 9px 12px 6px;
    display: block;
  }
}
