@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.movie-skeleton {
  &__top {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing * 2;

    @include minSm {
      margin-bottom: $spacing * 5;
    }
  }

  &__top-part-1 {
    margin-bottom: $spacing * 5;

    @include minSm {
      order: 1;
      margin-bottom: 0;
    }
  }

  &__top-item-1 {
    width: 99px;
    height: 34px;
    margin-bottom: $spacing;
  }

  &__top-item-2 {
    width: 217px;
    height: 16px;
  }

  &__top-part-2 {
    display: flex;
    gap: $spacing * 2;
  }

  &__gradient-1 {
    width: 97px;
    height: 144px;

    @include minSm {
      width: calc(100% + 32px);
      height: 205px;
      margin-top: -20px;
      margin-left: -16px;
      margin-right: -16px;
      margin-bottom: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__gradient-2 {
    width: 238px;
    height: 144px;
  }

  &__block {
    padding: $spacing * 4;

    &:not(:last-child) {
      margin-bottom: $spacing * 2;
    }
  }

  &__item-1 {
    height: 14px;

    &:not(:last-child) {
      margin-bottom: 9px;
    }

    &--1 {
      width: 94%;
    }

    &--2 {
      width: 80%;
    }

    &--3 {
      width: 92%;
    }

    &--4 {
      width: 97%;
    }

    &--5 {
      width: 37%;
    }
  }

  &__item-2 {
    height: 20px;
    width: 37%;

    &:not(:last-child) {
      margin-bottom: $spacing * 3;
    }
  }

  &__item-3-1 {
    width: 83px;
    height: 24px;
    margin-bottom: $spacing * 3;
  }

  &__part-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item-3-2 {
    width: 36px;
    height: 36px;
    margin-right: $spacing * 4;
  }

  &__item-3-3 {
    width: 173px;
    height: 14px;
    margin-bottom: 2px;
  }

  &__item-3-4 {
    width: 83px;
    height: 14px;
  }

  &__item-3-5 {
    width: 41px;
    height: 20px;
    margin-left: auto;
  }

  &__item-4-1 {
    width: 83px;
    height: 24px;
    margin-bottom: $spacing * 3;
  }

  &__part-4 {
    display: flex;
  }

  &__column-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
    margin-right: 13px;
  }

  &__circle-4 {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }

  &__item-4-2 {
    width: 50px;
    height: 14px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 43px;
    height: 38px;
  }

  &__button-item {
    width: 112px;
    height: 14px;
  }

  &__item-desk-1-1 {
    width: 59px;
    height: 24px;
    margin-bottom: $spacing * 3;
  }

  &__part-desk-1 {
    display: flex;
  }

  &__column-desk-1 {
    margin-right: $spacing * 2;
  }

  &__item-desk-1-2 {
    width: 83px;
    height: 124px;
    margin-bottom: $spacing * 3;
  }

  &__item-desk-1-3 {
    width: 68px;
    height: 14px;
    margin-bottom: 5px;
  }

  &__item-desk-1-4 {
    width: 44px;
    height: 14px;
  }

  &__item-desk-2-1 {
    width: 83px;
    height: 24px;
    margin-bottom: $spacing * 2;
  }

  &__part-desk-2 {
    display: flex;
  }

  &__circle-desk-2 {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    margin-right: $spacing * 3;
  }

  &__column-desk-2 {
    width: 100%;
  }

  &__item-desk-2-2 {
    width: 146px;
    height: 20px;
    margin-bottom: $spacing;
  }

  &__item-desk-2-3 {
    height: 17px;
    margin-bottom: 5px;
  }

  &__item-desk-2-4 {
    width: 198px;
    height: 17px;
  }

  &__item-desk-3-1 {
    width: 83px;
    height: 24px;
    margin-bottom: $spacing * 3;
  }

  &__item-desk-3-2 {
    height: 16px;
    margin-bottom: 10px;
  }

  &__item-desk-3-3 {
    width: 38%;
    height: 16px;
  }
}