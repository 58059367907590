@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.widget-wrapper {
  position: relative;
  margin-bottom: 24px;

  @include maxSm {
    padding-bottom: $spacing * 2;
    margin-bottom: 0;
  }

  @include themify($themes) {
    background-color: themed('backgroundSecondary');
  }
}

.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title-wrapper {
    margin: 0 0 5px;
  }

  &__title {
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-flex;
    height: $spacing * 5;
    padding: 0;

    &[disabled] {
      cursor: default;
    }

    &__header-cta {
      float: right;
    }
  }

  &__title-icon {
    width: ($spacing * 6);
    height: ($spacing * 6);
    margin: 0 7px -6px 0;
  }

  &__label {
    font-size: 20px;
    font-weight: normal;
    line-height: 1;
    margin-bottom: -6px;

    @include themify($themes) {
      color: themed('textPrimary');
    }

    &--lg {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 18px;
    margin: 0;

    @include themify($themes) {
      color: themed('textTertiary');
    }
  }
}

.widget-paper {
  position: relative;
  border-radius: $border-radius-xl;
  padding: ($spacing * 5) ($spacing * 4) ($spacing * 11);
  z-index: 0;
  overflow: hidden;

  @include themify($themes) {
    background: themed('backgroundPrimary');
    border: 1px solid themed('strokePrimary');
  }

  @include minSm {
    padding-bottom: ($spacing * 4);
  }

  &--animated-border {
    @include themify($themes) {
      border: none;
    }

    @include animated-border();
  }

  &--narrow-top-padding {
    padding-top: $spacing * 4;
  }
}

.widget-block {
  border-radius: $border-radius-m;

  @include themify($themes) {
    background: themed('backgroundSecondary');
    border: 1px solid themed('strokePrimary');
  }
}

.widget-footer {
  .feedback-button, .infocard-details {
    /* Delete this styles after the whole refactoring */
    position: static;
    left: auto;
    bottom: auto;
    top: auto;
    right: auto;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: $spacing * 3;

  @include maxSm {
    position: absolute;
    bottom: $spacing * 2;
    padding: ($spacing * 3) ($spacing * 4) ($spacing * 4);
    width: 100%;
  }

  &__button {
    flex: 0 0 auto;

    @include maxSm {
      order: 1;
    }

    &--feedback {
      margin-left: auto;
    }
  }

  .infocard-details {
    @include maxSm {
      order: 1;
    }
  }
}
