@import 'src/common/styles/variables.scss';

.link {
  color: $titanium-100;
  font-size: 13px;

  .dark-theme & {
    color: $silver-500;
  }

  &:hover, &:focus {
    text-decoration: underline;
  }
}
