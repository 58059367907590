@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.movie-profile-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
  min-width: 64px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &__img-container {
    position: relative;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    height: 40px;
    max-height: 40px;
    width: 40px;
    max-width: 40px;
    margin-bottom: 8px;

    @include themify($themes) {
      border: 1px solid themed('strokePrimary');
    }
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: cover;
    width: 100%;
    transform: translate(-50%,-50%);
    max-width: 100%;

    @include themify($themes) {
      color: themed('textTertiary');
    }
  }

  &__info {
    text-align: center;
    font-size: 12px;
    line-height: 14px;

    @include themify($themes) {
      color: themed('textTertiary');
    }

    &:visited {
      @include themify($themes) {
        color: themed('linkVisited');
      }
    }
  }
}