@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.instant-answer-timezone-widget {
  &__primary-timezone-time {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 400;
    display: block;

    @include themify($themes) {
      color: themed('textPrimary');
    }

    @include maxSm {
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 6px;
    }
  }

  &__primary-timezone-details {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
    font-style: normal;
    display: block;

    @include themify($themes) {
      color: themed('textTertiary');
    }

    @include maxSm {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 15px;
    }
  }

  &__secondary-timezone {
    font-size: 16px;

    @include maxSm {
      font-size: 14px;
      margin-bottom: $spacing * 2;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__secondary-timezone-time {
    font-style: normal;
    font-weight: 400;

    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  &__secondary-timezone-details {
    margin-left: 23px;
    font-style: normal;

    @include themify($themes) {
      color: themed('textTertiary');
    }

    @include maxSm {
      margin-left: 20px;
    }
  }

  &.widget-paper--animated-border::before {
    height: 700%;
    width: 120%;
    top: -302%;
    left: -10%;
    animation-duration: 7s;

    /*TODO: need to be rewritten with the more suitable solution*/
  }
}
