@import 'src/common/styles/variables.scss';

.check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  @include themify($themes) {
    color: themed('textSecondary');
  }

  .homepage--dark & {
    color: $silver-500;
  }

  &__wrapper {
    width: 100%;
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &:checked + .check__box {
      @include themify($themes) {
        background-color: themed('accentPrimary');
      }

      .homepage--dark & {
        background-color: $soft-blue-400;
      }

      &:before {
        content: '';
        position: absolute;
        margin-top: 4px;
        margin-left: 8px;
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(45deg);
        background-image: none;
        width: 6px;
        height: 10px;

        .dark-theme & {
          border-color: $white;
        }
      }
    }

    &:disabled + .check__box {
      background-color: #ebf0f5;
      box-shadow: none;
      border: none;

      .dark-theme & {
        background-color: #ebf0f5;
      }
    }

    &:checked:disabled + .check__box {
      &:before {
        border-color: $dark-grey;
      }
    }
  }

  &__box {
    position: relative;
    margin-top: 1px;
    margin-left: -30px;
    width: 22px;
    min-width: 22px;
    height: 22px;
    background: $lightest-grey;
    border: 1px solid $lavender;
    border-radius: 4px;

    .dark-theme &,
    .homepage--dark & {
      border-color: $light-transparent;
      background: $titanium-800;
    }
  }

  &__gap {
    width: 14px;
  }

  &__label-text {
    max-width: calc(100% - ($spacing * 8));

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      border-radius: 50%;
      padding: 2px 0 $spacing;

      svg {
        stroke: #b1b3be;
      }

      &:hover {
        background: rgba(86, 83, 210, 0.1);

        svg {
          stroke: $purple;
        }
      }
    }
  }

  .__react_component_tooltip {
    font-size: 12px;
    color: $white;
    line-height: 16px;
    background: $dark-grey;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: $border-radius-m;
    padding: $spacing * 2;
    max-width: 160px;

    .dark-theme & {
      color: $dark-grey;
      background: #F8F8F8;
      opacity: 1;
    }

    &.show {
      opacity: 1;
    }

    &.place-left::after {
      border-left-color: $dark-grey;

      .dark-theme & {
        border-left-color: #F8F8F8;
      }
    }

    &.place-top::after {
      border-top-color: $dark-grey;

      .dark-theme & {
        border-top-color: #F8F8F8;
      }
    }

    &.place-right::after {
      border-right-color: $dark-grey;

      .dark-theme & {
        border-right-color: #F8F8F8;
      }
    }

    &.place-bottom::after {
      border-bottom-color: $dark-grey;

      .dark-theme & {
        border-bottom-color: #F8F8F8;
      }
    }
  }

  &--reversed {
    flex-direction: row-reverse;
    justify-content: flex-end;

    .check {
      &__box {
        margin-left: 0;
      }
    }
  }
}
