@import 'src/common/styles/variables.scss';

/**
 * # base.scss - basic styles and helper classes.
 */

/**
 * ## Elements
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.grow {
  flex-grow: 1;
}

@supports (-moz-user-focus:none) {
  * {
    // scrollbar-color: rgba(0,0,0,0.5) transparent;
    scrollbar-width: thin;
    @include themify($themes) {
      scrollbar-color: themed('backgroundTertiaryPressed');
    }
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  @include themify($themes) {
    background: themed('backgroundTertiaryPressed');
  }
}

html, body {
  height: 100%;
}

html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

body {
  margin: 0;
  font-family: $font-family;
  /* - This property is deprecated. To make it work in Chrome
   * users have to activate it under Chrome://flags. */
  overflow: overlay;

  /**
   * This style block prevents the menu from scrolling
   * when its content isn't even long enough to be
   * scrolled.
   */
  .html-frozen & {
    position: fixed;
    overflow: hidden;
    max-height: 100vh;
    width: 100%;
  }
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

#root {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
}

#popup-root {
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
}

main {
  flex: 1 0 auto;
}

/**
 * ## Helper classes.
 */

.inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 ($spacing * 4);
}

.img {
  max-width: 100%;
}

@keyframes opacity{
  0%{opacity: 0;}
  100%{opacity: 1;}
}

.dark-theme {
  background-color: $dark;

  @supports (-moz-user-focus:none) {
    &,
    * {
      scrollbar-color: $light-transparent transparent;
    }
  }

  // ::-webkit-scrollbar-thumb {
  //   background: $titanium-300;
  // }
  ::-webkit-scrollbar-track {
    background-color: $titanium-700;
  }
}

.hl {
  background-color: transparent;
  color: inherit;
  font-weight: bold;
}

.disc-list-incognito {
  list-style: disc;
}

