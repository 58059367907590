@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.search-result {
  &__inner {
    margin: 0 auto;
    padding: 0;
    scrollbar-gutter: stable;

    @include themify($themes) {
      background: themed('backgroundTertiary');
    }

    @include minSm {
      max-width: 712px;
      padding: 0 ($spacing * 4);

      @include themify($themes) {
        background: none;
      }
    }

    @include minMd {
      display: flex;
      justify-content: space-between;
      max-width: 1276px;
      padding: ($spacing * 2) 38px 0;
    }
  }

  &__main {
    @include minMd {
      width: 680px;
      margin-right: 40px;
    }
  }

  &__aside {
    max-width: 420px;
    width: 100%;

    @include minMd {
      order: 1;
      margin-bottom: 50px;
    }

    @media (max-width: 1219px)  {
      max-width: 362px;
    }

    @include maxMd {
      max-width: 100%;
    }

    & >:not(:last-child) {
      @media (min-width: 1200px) {
        margin-bottom: 35px;
      }
    }
  }

  &__pagination {
    width: 100%;
    margin-bottom: $spacing * 2;

    @include themify($themes) {
      background-color: themed('backgroundSecondary');
    }

    @include maxSm {
      padding: 16px 0;
    }

    @media (max-width: 768px) {
      /* - `padding-bottom: 100px;` to preserve space on smaller
       * viewports for the 'back to top' button */
      padding: ($spacing * 4) ($spacing * 4) ($spacing * 5);
    }

    .pagination {
      @include maxSm {
        display: none;
      }
    }
  }

  &__pagination-button {
    position: relative;
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 24px;
    padding: 14px 18px;
    width: 100%;
    line-height: 14px;
    border: 1px solid $lavender;
    border-radius: 40px;
    font-size: 14px;
    color: #2D4FB2;

    @media (max-width: 1024px) {
      display: flex;
      margin-top: 0;
      margin-bottom: 0;
    }

    svg {
      position: absolute;
      right: 16px;

      path {
        stroke: #2D4FB2;

        .dark-theme & {
          stroke: $blue-light;
        }
      }
    }

    .dark-theme & {
      color: $blue-light;
      border-color: $asphalt-grey;
    }

    @include themify($themes) {
      color: themed('link');
    }
  }

  &__sponsored-results {
    margin-bottom: 30px;
  }

  &__related-search {
    content-visibility: auto;
    padding: ($spacing * 8) 0 ($spacing * 10);
    margin: 0;

    @include themify($themes) {
      background-color: themed('backgroundSecondary');
      border-top: 1px solid themed('strokePrimary');
      border-bottom: 1px solid themed('strokePrimary');
    }

    @media (max-width: 768px) {
      margin-bottom: 8px;
      border: none;
      padding: ($spacing * 4) 0;
    }
  }

  .related-search {
    &__texts {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: ($spacing * 4);

      @include maxSm {
        margin-left: $spacing * 4;
      }
    }

    &__title {
      margin: 0 0 ($spacing * 3);
      font-size: 20px;
      font-weight: normal;
      line-height: 25px;
      color: $primary-color;

      @include maxSm {
        padding: 0 ($spacing * 4);
      }

      .dark-theme & {
        color: $lightest-grey;
      }

      &--sm {
        line-height: 20px;
        font-size: 14px;
      }
    }

    &__items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: ($spacing * 2);
      text-align: left;
      line-height: 1.2;
      list-style: none;
      margin: 0;
      padding: 0;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 ($spacing * 4);
      }
    }

    &__item {
      position: relative;
      list-style: none;
      border-radius: 40px;
      overflow: hidden;
      padding: 19px ($spacing * 10) 15px ($spacing * 4);
      @extend %ellipsis;

      @include themify($themes) {
        background-color: themed('backgroundPrimary');
      }
    }

    &__item-link {
      font-size: 14px;
      line-height: 1;
      cursor: pointer;
      border: none;
      text-align: left;
      width: 100%;
      @extend %ellipsis;

      @include themify($themes) {
        color: themed('link');
      }

      &:nth-child(n+7) {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: $spacing * 4;

      @include themify($themes) {
        color: themed('iconSecondary');
      }
    }
  }

  .widget {
    content-visibility: auto;
    margin-bottom: ($spacing * 10);

    @media (max-width: 1024px) {
      margin: 0 0 ($spacing * 2);
      padding: 16px;
      border: none;
      border-radius: 0;
      box-shadow: 0 2px 15px rgba($black, 0.1);

      @include themify($themes) {
        background-color: themed('backgroundSecondary');
      }
    }

    &.images-widget {
      @media (max-width: 1024px) {
        box-shadow: none;
        padding: 0;
      }

      @include maxSm {
        padding: $spacing * 4;
      }
    }

    &__list {
      margin-bottom: 10px;
    }

    &__images-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -2px;
      max-height: calc(($widget-image-height-small * 2) + ($spacing * 2));
      overflow: hidden;

      @media (min-width: 768px) {
        max-height: calc(($widget-image-height-big * 2) + ($spacing * 2));
      }
    }

    &__link {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 0;
      border: none;
      background: transparent;
      cursor: auto;
    }

    &__link-more {
      padding: 11px 10px 11px 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $lavender;
      border-radius: 40px;
      cursor: pointer;

      @include themify($themes) {
        color: themed('link');
      }

      .dark-theme & {
        border-color: $light-transparent;
      }

      @media (max-width: 1024px) {
        position: relative;
        width: 100%;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        margin-left: 6px;
        @media (max-width: 1024px) {
          position: absolute;
          right: 15px;
        }

        path {
          .dark-theme & {
            stroke: $light-secondary;
          }
        }
      }

      &:before {
        content: '';
        position: absolute;
        height: 1px;
        width: calc(50% - 60px);
        right: 0;
        top: 50%;
        background: $lavender;

        .dark-theme & {
          background: $light-transparent;
        }

        @media (max-width: 1024px) {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: calc(50% - 60px);
        left: 0;
        top: 50%;
        background: $lavender;

        .dark-theme & {
          background: $light-transparent;
        }

        @media (max-width: 1024px) {
          display: none;
        }
      }
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    padding-bottom: 8px;

    &__body {
      width: 17px;
      height: 17px;
      border: 2.8px solid $block;
      border-radius: 50%;
      animation: load 1.1s infinite linear;

      @keyframes load {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    &__inner {
      position: relative;

      svg {
        position: absolute;
        top: 0;
        left: 38%;
        transform: translate(0, -19%);
      }
    }
  }
}
