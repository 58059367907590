/**
 * # Variables.
 */

 /**
 * ## Color Palette for Marketing
 * - This palette is not to be used in SERP and
 * outside of so called marketing purposes! ✋🥶
 */
$marketing-pink-100: #d24ff5;

$marketing-blue-gray-200: #EAECF7;

$marketing-purple-50: #c4aaef;
$marketing-purple-300: #8000ff;
$marketing-purple-500: #241f46;

$marketing-blue-20: #F3F6FF;
$marketing-blue-100: #605dd4;
$marketing-blue-110: #6B5CFF;
$marketing-blue-150: #6053E5;
$marketing-blue-200: #5c54cf;
$marketing-blue-400: #5a4bca;
$marketing-blue-450: #5549CB;
$marketing-blue-500: #5341c4;
$marketing-blue-800: #1A1A43;

$marketing-blue-gray-100: #9789f5;
$marketing-blue-gray-600: #252933;
$marketing-blue-gray-700: #161626;
$marketing-blue-gray-800: #131326;
$marketing-blue-gray-900: #0F0F26;

$marketing-blue-black-500: #0B0B1B;


$marketing-turquoise-100: #81bdf9;
$marketing-turquoise-200: #81A4FF;

$marketing-green-100: #a2f7e1;

$marketing-gray-900: #111;

/**
 * ## Regular Colors palette
 * To be used within SERP.
 */
$titanium-800: #0E1014;
$titanium-700: #15181F;
$titanium-600: #1D2129;
$titanium-500: #242933;
$titanium-400: #2B313D;
$titanium-300: #323947;
$titanium-200: #394152;
$titanium-100: #40495C;

$silver-800: #646E82;
$silver-700: #7A8599;
$silver-600: #969EAF;
$silver-500: #B1B7C4;
$silver-400: #C4C9D3;
$silver-300: #D6DAE3;
$silver-200: #E4E8F0;
$silver-100: #F2F4F8;

$grey-800: #6C7380;
$grey-700: #868C98;
$grey-600: #9FA4B0;
$grey-500: #B4B9C2;
$grey-400: #C7CAD1;
$grey-300: #D8DBDF;
$grey-200: #E4E6E9;
$grey-100: #F0F1F2;

$platinum-800: #D5D9E0;
$platinum-700: #DCDFE5;
$platinum-600: #E3E5EB;
$platinum-500: #E8EAF0;
$platinum-400: #EDF0F5;
$platinum-300: #F2F5FA;
$platinum-200: #F7FAFF;
$platinum-100: #FFFFFF;

$thick-blue-800: #3947A3;
$thick-blue-700: #4151BA;
$thick-blue-600: #495BD1;
$thick-blue-500: #495BD1;
$thick-blue-400: #566CF7;
$thick-blue-300: #667BFF;
$thick-blue-200: #7386FF;
$thick-blue-100: #8091FF;

$soft-blue-800: #556FC2;
$soft-blue-700: #5B77D1;
$soft-blue-600: #6280E0;
$soft-blue-500: #6989F0;
$soft-blue-400: #7092FF;
$soft-blue-300: #7D9BFF;
$soft-blue-200: #8AA5FF;
$soft-blue-100: #96AFFF;

$cobalt-200: #1549D1;
$cobalt-100: #7DB9FF;

$purple-200: #7430A1;
$purple-100: #B68AD4;

$red-200: #D6322D;
$red-100: #DB5A53;

$yellow-200: #FFCC00;
$yellow-100: #FFD940;

$green-200: #16B24B;
$green-100: #4CB871;

/**
 * ## Gradients
 */
$gradient-1: linear-gradient(158.69deg, #4A3378 12.94%, #4C357D 20%, #594198 32.46%, #7156CA 57.62%, #8064E9 76.72%, #8669F5 87.37%);
$gradient-2: linear-gradient(150.21deg, #5F419A 5.7%, #61439D 7.06%, #755DC3 27.09%, #8470DE 45.61%, #8D7BEF 61.88%, #907FF5 74.18%);
$gradient-3: linear-gradient(50.14deg, #6F55C7 9.28%, #905DD9 37.4%, #9A59CF 44.96%, #B64EB5 57.9%, #E03D8C 73.96%, #E03D8C 82.99%);
$gradient-4: linear-gradient(8.91deg, #724FD2 5.34%, #7A51D0 20.8%, #9055CA 44.76%, #AE5BC2 70.08%);
$gradient-5: linear-gradient(53.67deg, #7E64DC 12.55%, #A159EB 41.14%, #E959A6 68.32%, #F55196 87.77%);
$gradient-6: linear-gradient(270deg, #8669F5 2.22%, #4A3378 62.83%);
$gradient-7: linear-gradient(49.75deg, #9689F5 7.8%, #BA82DE 22.94%, #E579C4 42.7%, #F576BA 52.83%, #F569AF 77.55%, #F569AF 77.94%);

/**
 * ## New themes map
 */
$themes: (
  light: (
    backgroundPrimary: $platinum-300,
    backgroundPrimaryHover: $platinum-500,
    backgroundPrimaryPressed: $platinum-700,
    backgroundPrimaryPressedAlt: $platinum-500,
    backgroundPrimaryFocus: $thick-blue-300,
    backgroundSecondary: $platinum-100,
    backgroundSecondaryHover: $platinum-300,
    backgroundSecondaryPressed: $platinum-500,
    backgroundTertiary: $platinum-400,
    backgroundTertiaryHover: $platinum-600,
    backgroundTertiaryPressed: $platinum-800,
    backgroundTooltip: $titanium-500,
    strokePrimary: $platinum-500,
    strokeSecondary: $silver-200,
    strokeTertiary: $silver-300,
    strokeQuartary: $silver-300,
    strokeFocus: $thick-blue-400,
    textPrimary: $titanium-400,
    textSecondary: $titanium-100,
    textTertiary: $silver-800,
    textButton: $platinum-100,
    iconPrimary: $silver-500,
    iconSecondary: $silver-700,
    accentPrimary: $thick-blue-600,
    accentPrimaryHover: $thick-blue-400,
    accentPrimaryPressed: $thick-blue-200,
    accentPrimaryDisabled: $grey-500,
    modalPrimary: $platinum-100,
    modalPrimaryHover: $platinum-300,
    modalPrimaryPressed: $platinum-500,
    modalSecondary: $platinum-400,
    modalSecondaryHover: $platinum-600,
    modalSecondaryPressed: $platinum-800,
    modalStrokePrimary: $silver-200,
    modalStrokeSecondary: $platinum-500,
    link: $cobalt-200,
    linkVisited: $purple-200,
    systemPrimary: $red-200,
    systemSecondary: $yellow-200,
    systemTertiary: $green-200,
    transparentLayer: rgba($titanium-800, 50%),
    inputTextColor: $titanium-100,
    homeOmniboxBackground: $platinum-100,
    homeOmniboxSuggestionHover: $platinum-300,
    homeOmniboxSuggestionPressed: $platinum-500,
    homeOmniboxStroke: $platinum-500,
    test: red,
  ),
  dark: (
    backgroundPrimary: $titanium-500,
    backgroundPrimaryHover: $titanium-300,
    backgroundPrimaryPressed: $titanium-100,
    backgroundPrimaryPressedAlt: $titanium-300,
    backgroundPrimaryFocus: $soft-blue-200,
    backgroundSecondary: $titanium-700,
    backgroundSecondaryHover: $titanium-500,
    backgroundSecondaryPressed: $titanium-300,
    backgroundTertiary: $titanium-800,
    backgroundTertiaryHover: $titanium-600,
    backgroundTertiaryPressed: $titanium-400,
    backgroundTooltip: $titanium-100,
    strokePrimary: $titanium-400,
    strokeSecondary: $titanium-200,
    strokeTertiary: $silver-300,
    strokeQuartary: $titanium-400,
    strokeFocus: $soft-blue-400,
    textPrimary: $grey-100,
    textSecondary: $silver-500,
    textTertiary: $silver-700,
    textButton: $platinum-100,
    iconPrimary: $grey-800,
    iconSecondary: $grey-600,
    accentPrimary: $soft-blue-400,
    accentPrimaryHover: $soft-blue-600,
    accentPrimaryPressed: $soft-blue-800,
    accentPrimaryDisabled: $titanium-100,
    modalPrimary: $titanium-500,
    modalPrimaryHover: $titanium-300,
    modalPrimaryPressed: $titanium-100,
    modalSecondary: $titanium-800,
    modalSecondaryHover: $titanium-600,
    modalSecondaryPressed: $titanium-400,
    modalStrokePrimary: $titanium-400,
    modalStrokeSecondary: $titanium-200,
    link: $cobalt-100,
    linkVisited: $purple-100,
    systemPrimary: $red-100,
    systemSecondary: $yellow-100,
    systemTertiary: $green-100,
    transparentLayer: rgba($titanium-300, 50%),
    inputTextColor: $soft-blue-400,
    homeOmniboxBackground: $titanium-400,
    homeOmniboxSuggestionHover: $titanium-300,
    homeOmniboxSuggestionPressed: $titanium-100,
    homeOmniboxStroke: $titanium-200,
    test: yellow,
  ),
);

/**
 * ## Old tokens
 */

$lightest-grey: #fafaff;
$light-grey: #ebecf8;
$grey: #8e9298;
$blue-secondary: #2289d2;
$dark-blue: #1f2845;
$lightest-black: #111;
$light-black: #222;
$dark-lavender: #33394f;
$tmdb-green: #63CD82;
$light-transparent: rgba(255, 255, 255, .15);
$url: #138982; /* rgba(19, 137, 130, 1) */
$grey-snow: #F1F3F4;
$dark-mode-description: rgba(255, 255, 255, .7);

/*
* ### General colors:
*/
$white: #fff;
$black: #000;
$dark: $titanium-700;

/*
* ### Old Exception colors:
*/
$grey-snow: $platinum-400;
$blue: $cobalt-200;
$purple: $thick-blue-600;
$onyx: $silver-500;
$light-secondary: $silver-500;
$lavender: $platinum-500;
$text: $titanium-400;
$dark-grey: $titanium-100;
$secondary-color: $silver-800;
$block: $platinum-400;
$dark-mode-block: $titanium-800;
$primary-color-dark-theme: $grey-100;
$button-color-dark-theme: $soft-blue-400;
$blue-light: $cobalt-100;
$l2: $titanium-500;
$l3: $titanium-300;
$asphalt-grey: $titanium-400;
$secondary-color-dark-theme: $silver-500;


/**
* ### headings
*/
$primary-color: rgba(39, 44, 61, 1);

/**
 * ### Buttons
 */
$button-color-light: #5653d220;
$button-hover: #f6f6fc; /* rgba(86, 83, 210, 0.05) */
$button-active: #eeeefa; /* rgba(86, 83, 210, 0.1) */

/**
 * ## Font Families
 */
$font-family: "Helvetica", sans-serif;
$font-family-secondary: "Inter", sans-serif;
$font-family-documents: "Calibri Light", sans-serif;

/**
 * ## Spacings
 */
$measure: 4px;
$spacing: $measure;

/**
 * ## Border Radius
 */
$border-radius-s: $measure;
$border-radius-m: $measure * 2;
$border-radius-l: $measure * 3;
$border-radius-xl: $measure * 4;
$border-radius-xxl: $measure * 13;
$border-radius-xxxl: $measure * 15;
$border-radius-xxxxl: $measure * 30;

/**
 * ## Image widget heights
 */
$widget-image-height-small: 100px;
$widget-image-height-big: 172px;

/*
 * ## Media queries / breakpoints
 */
/* - This breakpoint determines the layout switch
 * inside their respective search pages.
 *
 * `const imageResultsLayoutBreakPoint` is also
 * set in JavaScript.
 */
$image-results-layout-break-point: 760px;
$news-results-layout-break-point: 710px;

$inner-body-breakpoint: 712px;
$inner-body-breakpoint-big: 1276px;

$screen-max-xsm: 575px;
$screen-min-xsm: 576px;
$screen-max-sm: 759px;
$screen-min-sm: 760px;
$screen-max-sm-md: 1023px;
$screen-min-sm-md: 1024px;
$screen-min-md: 1162px;
$screen-max-md: 1161px;
$screen-max-lg: 1679px;
$screen-min-lg: 1680px;

$screen-min-md-aside: 1221px;
$screen-max-md-aside: 1220px;

/**
 * ## Z-indexes
 */
$z-index-default: 1;
$z-index-header-default: 10;
$z-index-header-settings: $z-index-header-default + 1;
$z-index-footer-default: $z-index-header-default - 1;
$z-index-fullscreen-settings: 20;

/*
 * Implementation of themes
 */
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .#{$theme}-theme & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}