@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.stock-widget-skeleton {
  &__header {
    margin-bottom: $spacing * 5;

    @include minSm {
      display: flex;
      justify-content: space-between;
    }
  }

  &__block {
    width: 100%;
    display: flex;
    flex-direction: column;

    &--1 {
      margin-bottom: $spacing * 2;

      @include minSm {
        margin-bottom: 0;
      }
    }

    &--2 {
      @include minSm {
        align-items: flex-end;
      }
    }
  }

  &__item-1-1 {
    width: 90px;
    height: 20px;
    margin-bottom: 2px;

    @include minSm {
      width: 300px;
      height: 22px;
      margin-bottom: 4px;
    }
  }

  &__block-bottom {
    display: flex;
  }

  &__item-1-2 {
    width: 158px;
    height: 14px;

    @include minSm {
      width: 91px;
      height: 47px;
      margin-right: 16px;
    }
  }

  &__item-1-3 {
    display: none;

    @include minSm {
      align-self: flex-end;
      display: block;
      width: 140px;
      height: 22px;
      margin-bottom: 4px;
    }
  }

  &__item-2-1 {
    width: 51px;
    height: 24px;
    margin-bottom: 3px;

    @include minSm {
      width: 140px;
      height: 22px;
    }
  }

  &__item-2-2 {
    width: 158px;
    height: 14px;
  }

  &__field {
    height: 223px;
    border-width: 1px;
    border-style: solid;
    margin-bottom: $spacing * 2;

    @include themify($themes) {
      border-color: themed('strokePrimary');
    }

    @include minSm {
      height: 239px;
    }
  }

  &__details {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing * 3;
    column-gap: $spacing * 8;
    padding: $spacing ($spacing * 4) 34px;

    @include minSm {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: $spacing * 10;
      padding-bottom: ($spacing * 4);
    }
  }

  &__details-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: $spacing * 3;

    &:nth-child(n + 4) {
      border-top-width: 1px;
      border-top-style: solid;

      @include themify($themes) {
        border-color: themed('strokePrimary');
      }
    }

    &:nth-child(n + 3) {
      @include maxSm {
        border-top-width: 1px;
        border-top-style: solid;

        @include themify($themes) {
          border-color: themed('strokePrimary');
        }
      }
    }
  }

  &__details-brick {
    width: 42px;
    height: 12px;
  }

  &__details-buttons {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 14px;
    display: flex;
    justify-content: space-between;
    gap: 3px;

    @include minSm {
      display: none;
    }
  }

  &__details-button {
    width: 6px;
    height: 6px;
    min-width: 6px;
    min-height: 6px;
  }
}
