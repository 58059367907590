@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.instant-answer-skeleton {
  &__item-1 {
    width: 106px;
    height: 14px;
    margin-bottom: $spacing;
  }

  &__item-2 {
    width: 172px;
    height: 34px;
  }
}