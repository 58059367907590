@import 'src/common/styles/utilities.scss';

.ellipsis {
  font-size: 14px;
  line-height: 21px;
  @extend %link;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
