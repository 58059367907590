@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.browser-cta-button {
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 34px;
  height: 46px;
  font-size: 14px;
  cursor: pointer;
  padding: $spacing * 3;

  @include themify($themes) {
    background-color: themed('backgroundPrimary');
    border-color: themed('strokePrimary');
    color: themed('textPrimary');
  }

  &__icon {
    margin-right: $spacing * 2;
  }
}