@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.feedback-button {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  border: none;
  padding: 0;
  margin: 0;
  width: initial;

  @include themify($themes) {
    color: themed('textTertiary');
  }

  &--infocard {
    position: absolute;
    right: -6px;
    bottom: -26px;

    @media (max-width: 1024px) {
      right: 14px;
    }
  }

  &:hover {
    text-decoration: underline;
  }

  &__icon {
    margin-right: $spacing;

    @include themify($themes) {
      color: themed('iconPrimary');
    }
  }
}

.feedback-fake-link {
  @extend %link;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.popup-overlay {
  @include themify($themes) {
    background: themed('transparentLayer');
  }
}

.popup-content.feedback-popup-content {
  height: 100%;
  width: 100%;

  @media (max-width: 420px) {
    /* - Todo: needs refactoring. This style overwrites
     * an inline style.
     */
    margin: auto 0 0 0 !important;
  }
}
