/**
 * # Page Footer
 */
/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.pagefooter {
    /* - Todo: lots of colors used on the rebranding aren't
     * part of the new color system. Align this with designers.
     */
    background-color: #0F0F26;
    border-top: 1px solid rgba($white, .1);
    color: rgba($white, .6);
    position: relative;
    z-index: 6;

    &--theme-tempest {
        padding-top: ($spacing * 14);
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
    }

    &__row-left {
        width: 100%;

        @include minSm {
            padding-right: ($spacing * 10);
            width: 52%;
        }
    }

    &__row-middle {
        width: 100%;

        @include minSm {
            padding-right: ($spacing * 10);
            width: 28%;
        }

        @include minMd {
            width: 22%;
        }
    }

    &__row-right {
        margin-bottom: $spacing * 6;
        width: 100%;

        @include minSm {
            margin-bottom: 0;
            width: 20%;
        }

        @include minMd {
            width: 26%;
        }
    }

    &__downloads-social {
        @include maxSm {
            order: 2;
        }
    }

    &__tempest {
        @include maxSm {
            order: 1;
        }
    }

    &__headline-mini {
        @extend %homepage-miniheadline;
        width: 100%;
    }

    /**
     * ### Download Links
     */

    &__download-list {
        margin-bottom: $spacing * 14;
    }

    &__download-link,
    &__download-link.link {
        color: $white;
        display: inline-block;
        font-size: 14px;
        padding: ($spacing * 2) 0;
        transition: color .2s ease-in;

        &:hover {
            color: rgba($white, .6);
            text-decoration: none;
        }
    }

    /**
     * ### External Links
     */
    &__link-list {
        @include minSm {
            margin-bottom: $spacing * 10;
        }
    }

    &__link-item {
        &:last-of-type {
            .pagefooter__link-external {
                border-bottom: none;
            }
        }
    }

    &__link-external {
        border-bottom: 1px solid rgba($white, .1);
        display: block;
        font-size: 20px;
        font-family: "Poppins Light", sans-serif;
        padding: 11px ($spacing * 14) 11px 0;
        position: relative;
        @extend %arrow-right-pseudo-element;

        /* - Overwriting react component's `.link` default class. */
        .homepage &, .browser-page & {
            color: $white;
        }

        &:hover {
            text-decoration: none;
            color: rgba($white, .6);
        }

        &::after {
            right: $spacing;
            top: 17px;
        }
    }

    /**
     * ### Links Mini
     */
    &__link-list-mini {
        margin-bottom: $spacing * 5;
    }

    &__mini-link {
        color: rgba($white, .6);
    }

    /**
     * ### Links Social
     */
    &__social-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__social {
        display: flex;
        margin-bottom: $spacing * 2;
    }

    &__social-item {
        margin-right: $spacing * 4;
    }

    &__social-link {
        align-items: center;
        background-color: rgba($white, .04);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 50%;
        border-radius: 100%;
        display: flex;
        height: 32px;
        justify-content: center;
        transition: background-color 0.2s ease-in-out;
        width: 32px;

        &:hover {
            background-color: rgba($white, .08);
        }

        &--facebook {
            background-image: url("/img/homepage_rebrand/icons/facebook.svg");
        }

        &--instagram {
            background-image: url("/img/homepage_rebrand/icons/instagram.svg");
        }

        &--linkedin {
            background-image: url("/img/homepage_rebrand/icons/linkedin.svg");
        }

        &--twitter {
            background-image: url("/img/homepage_rebrand/icons/twitter.svg");
        }

        &--github {
            background-image: url("/img/homepage_rebrand/icons/github.svg");
        }
    }

    /**
     * ### Boilerplate - The End of Everything.. ^_^
     */
    &__boilerplate {
        background-color: $marketing-blue-black-500;
        position: relative;
    }

    &__boilerplate-body {
        display: flex;
        justify-content: space-between;
        margin-top: $spacing * 10;
        padding-top: $spacing;
        padding-bottom: ($spacing * 12);
        position: relative;
        width: 100%;

        .pagefooter--theme-tempest & {
            padding-top: ($spacing * 12);
        }
    }

    &__boilerplate-imprint {
        @media (min-width: 348px) {
            text-align: right;
        }

        @include minSm {
            display: flex;
            gap: $spacing * 4;
        }
    }

    &__imprint {
        line-height: 2.25;
    }
}