@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.error-component {
  display: flex;
  flex: 1 1 100%;

  @media (max-width: 768px) {
    display: block;

    .video-result & {
      @include themify($themes) {
        background-color: themed('backgroundSecondary');
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 40px 40px 80px;
    max-width: 1000px;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: $spacing * 5;
      margin: 0;
    }

    &--left {
      @media (max-width: 768px) {
        align-items: flex-start;
      }
    }
  }

  &__info {
    flex: 1 1 50%;
    max-width: 425px;
    margin-right: 50px;

    @media (max-width: 768px) {
      text-align: center;
      margin-right: 0;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: ($spacing * 2) 0 ($spacing * 3);

    @include themify($themes) {
      color: themed('textPrimary');
    }
  }

  &__text {
    font-size: 16px;
    line-height: 25px;
    color: $dark-grey;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    ul {
      margin: 0;
      padding: 0 0 0 16px;
    }

    .dark-theme & {
      color: $white;
    }
  }

  &__query {
    @extend %ellipsis;
  }

  .mobile {
    display: inline;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .button-as-link {
    @extend %button-as-link;
  }

  &__picture {
    flex: 1 1 50%;
    max-width: 500px;
    text-align: center;

    .error-component--left & {
      text-align: left;
    }

    @media (max-width: 768px) {
      flex: 1 1 100%;
    }
  }

  &__img {
    max-width: 100%;

    .dark-theme & {
      display: none;
    }

    &--dark {
      display: none;

      .dark-theme & {
        display: inline-block;
      }
    }

    @media (max-width: 768px) {
      height: 100%;
    }

    @media (max-height: 667px) {
      max-width: 70%;
    }

    @media (max-height: 543px) {
      max-width: 40%;
    }
  }

  &__button {
    display: none;

    @media (max-width: 768px) {
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $white;
      background: $purple;
      border: none;
      border-radius: 8px;
      width: 239px;
      height: 50px;
    }

    .dark-theme & {
      color: $white;
      background: $button-color-dark-theme;
    }
  }

  &__link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    display: inline-block;
    line-height: 24px;
    outline: none;
    text-align: left;
    text-decoration: none;
    padding: 0;

    @include themify($themes) {
      color: themed('link');
    }

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}
