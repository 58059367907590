@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.tips-card {
  position: absolute;
  background-image: radial-gradient(rgba($white, .1) 45px, rgba($white, 0.05) 46px, rgba($white, 0.05) 65px, transparent 66px, transparent);
  background-size: 129px 129px;
  background-position: calc(100% + 30px) -30px;
  background-repeat: no-repeat;
  border-radius: $border-radius-m;
  padding: $spacing * 4;

  @include themify($themes) {
    background-color: themed('accentPrimary');
  }

  @media (max-width: 575px){
    border-radius: 0;
  }

  &::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 2px;
    transform: rotate(45deg);
    z-index: -1;

    @include themify($themes) {
      background-color: themed('accentPrimary');
    }
  }

  &--top-right {
    &::before {
      top: -5px;
      right: 17px;
      background-image: linear-gradient(rgba($white, .1), rgba($white, .1));
    }
  }

  &--bottom-right {
    &::before {
      bottom: -5px;
      right: 60px;
    }
  }

  &__icon {
    position: absolute;
    top: $spacing * 4;
    right: $spacing * 4;
    color: $white;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $primary-color-dark-theme;
    margin-bottom: 7px;
    max-width: 292px;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $dark-mode-description;
    margin-bottom: 17px;
    max-width: 292px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__dots {
    display: flex;
    gap: $spacing;
  }

  &__dot {
    width: 8px;
    height: 8px;
    background: $white;
    opacity: 0.5;
    border-radius: 50%;

    &--active {
      opacity: 1;
    }
  }

  &__buttons {
    display: flex;
    gap: $spacing * 2;
  }

  &__button {
    font-family: $font-family;
    font-size: 16px;
    line-height: 18px;
    color: $primary-color-dark-theme;
    border: 2px solid transparent;
    border-radius: $border-radius-m;
    height: 40px;
    padding: 10px;
    min-width: 87px;

    &--outlined {
      font-weight: 700;
      color: $white;
      border-color: $white;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}