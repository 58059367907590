@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.menu {
  font-family: Helvetica, Arial, sans-serif;

  &__close-button {
    position: absolute;
    top: 9px;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    @include themify($themes) {
      color: themed('iconSecondary');
    }

    &:hover {
      @include themify($themes) {
        background: themed('modalSecondaryHover');
      }
    }

    &:active {
      @include themify($themes) {
        background: themed('modalSecondaryPressed');
      }
    }

    &:focus-visible {
      @include themify($themes) {
        outline: 2px solid themed('strokeFocus');
      }
    }

    @media (max-width: 699px) {
      top: 12px;
      right: 16px;
    }
  }

  &__button {
    border: none;
    outline: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-left: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.25s ease-out;

    @include minSm {
      margin-top: -1px;
    }

    .navbar-sticky & {
      margin-top: 0;
    }
  }

  &__body {
    border-bottom-left-radius: $border-radius-xl;
    border-bottom-right-radius: $border-radius-xl;
    box-shadow: 0 2px 15px rgba($black, 0.4);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    /* - This duplicated `max-height` declaration is needed as
     * fallback for iOS devices: */
    max-height: 100vh;
    max-height: var(--app-height);
    overflow: auto;
    padding: ($spacing * 6) ($spacing * 6) ($spacing * 6);
    scrollbar-width: 4px;
    z-index: $z-index-header-settings;
    overscroll-behavior: contain;

    .dark-theme & {
      @media (min-width: 700px) {
        border: 1px solid $titanium-400;
      }
    }

    @include themify($themes) {
      background-color: themed('backgroundTertiary');
      scrollbar-color: themed('backgroundTertiaryPressed') transparent;
    }

    @media (min-width: 700px) {
      position: absolute;
      top: -8px;
      right: 0;
      left: auto;
      overflow: visible;
      padding-top: $spacing * 6;
      border-top-left-radius: $border-radius-xl;
      border-top-right-radius: $border-radius-xl;
      max-width: 375px;
      min-width: 375px;
      min-height: auto;
      max-height: inherit;
      max-height: 90vh;
      height: auto;
      box-shadow: 0 4px 12px rgba($black, 0.15);
    }

    @media (min-width: 768px) {
      max-height: calc(100vh - 90px);
      overflow-y: auto;
      top: 0;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      @include themify($themes) {
        background: themed('backgroundTertiaryPressed');
      }
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

  }

  &__section {
    color: $grey;
    margin-bottom: $spacing * 5;

    .dark-theme &,
    .homepage--dark & {
      border-color: rgba(255, 255, 255, 0.03);
    }

    &--nav {
      display: none;

      @include maxSm {
        display: block;
        margin-bottom: $spacing * 5;
      }
    }

    &--others {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 12px;
    font-weight: normal;
    margin: 0 0 $spacing * 3;
    text-transform: uppercase;

    @include themify($themes) {
      color: themed('textTertiary');
    }

    &--settings {
      margin: 0;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;

    @include themify($themes) {
      color: themed('textSecondary');
    }

    .homepage--dark & {
      color: $silver-500;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__other-settings {
    border-radius: $border-radius-m;
    padding: 6px ($spacing * 4);
    margin-top: ($spacing * 3);

    @include themify($themes) {
      background: themed('modalPrimary');
      border: 1px solid themed('modalStrokePrimary');
    }
  }

  &__settings-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ($spacing * 3) 0;
    font-size: 14px;
    line-height: 20px;

    @include themify($themes) {
      border-bottom: 1px solid themed('modalStrokeSecondary');
      color: themed('textSecondary');
    }

    &:last-child {
      border: none;
    }
  }

  &__tooltip-icon {
    margin-right: $spacing * 4;
    margin-left: auto;
  }

  &__toggle-checkbox {
    width: 100%;
  }

  .react-select-container {
    border-radius: 44px;

    @include themify($themes) {
      border: 1px solid themed('modalStrokePrimary');
    }
  }

  .react-select {
    &__control {
      padding: 6px ($spacing * 4);
      font-size: 14px;
      line-height: 1.15;
      border-radius: 44px;
      border: $spacing solid;

      @include themify($themes) {
        background: themed('modalPrimary');
        border-color: themed('modalPrimary');
      }

      &:hover {
        @include themify($themes) {
          background: themed('modalPrimaryHover');
        }

        .react-select__single-value {
          @include themify($themes) {
            color: themed('textSecondary');
          }
        }
      }

      &:active {
        @include themify($themes) {
          background: themed('modalPrimaryPressed');
        }
      }
    }

    &__indicator {
      padding: 0;
    }

    &__value-container,
    &__single-value {
      font-size: 14px;
      padding: 0;

      @include themify($themes) {
        color: themed('textSecondary');
      }
    }

    &__menu {
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      padding: $spacing * 2;
      font-size: 14px;
      margin-top: 4px;

      @include themify($themes) {
        border: none;
      }
    }

    &__menu-list {
      padding: 0;
    }

    &__option {
      border-radius: 30px;
      padding-left: ($spacing * 3);
      padding-right: ($spacing * 3);

      &--is-focused,
      &:hover {
        background-color: rgba(86, 83, 210, 0.05);

        .dark-theme &,
        .homepage--dark & {
          background-color: rgba(255, 255, 255, 0.03);
        }
      }
    }
  }

  &__tips-card {
    right: -8px;
    top: 50px;
    z-index: $z-index-header-settings + 2;
    width: 375px;

    @media (max-width: 699px){
      right: -10px;
      top: 40px;
      width: auto;
    }

    @media (max-height: 765px) {
      width: 365px;
    }

    &--settings {
      top: auto;
      bottom: 51px;
      right: -41px;

      @media (max-width: 699px){
        right: -41px;
        left: -41px;
        width: auto;
      }
    }
  }

  &__toggle-settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: none;
    width: 100%;
    padding: 0;
    cursor: pointer;
  }

  &__toggle-settings-icon {
    @include themify($themes) {
      color: themed('iconSecondary');
    }

    &--fold {
      transform: rotate(180deg);
    }
  }
}
